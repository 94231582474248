import React, { Component, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { activateNonAuthLayout } from "../../../store/actions";
import { logoutUser } from "../../../helpers/authUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useOktaAuth } from "@okta/okta-react";

// rewrite the class component to a functional component
export const Logout = () => {
  const { oktaAuth, authState } = useOktaAuth();

  const history = useHistory();

  const logoutOktaAuth = async () => {
    await oktaAuth.signOut();
  };

  useEffect(() => {
    // logout();
    activateNonAuthLayout();
    // logoutUser().then((data) => {
    // localStorage.clear();
    // console.log(logoutOktaAuth());
    logoutOktaAuth();
    // if (data.status === "success") {
    // history.push("/login");
    // }
    // });
  }, [history]);
  return (
    <React.Fragment>
      <h1>&nbsp;</h1>
    </React.Fragment>
  );
};

// class Logout extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {  };
//     }

//     componentDidMount() {
//         this.props.activateNonAuthLayout();
//         logoutUser().then(data => {
//             localStorage.clear();
//             if(data.status === 'success') {
//                 this.props.history.push('/login');
//             }
//         })
//     }

//     render() {
//         return (
//             <React.Fragment>
//                <h1>&nbsp;</h1>
//             </React.Fragment>
//         );
//     }
// }

const mapStatetoProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStatetoProps, { activateNonAuthLayout })(Logout)
);
