const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT;
const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;

export const isAuthenticated = () => {
  const oktaToken = localStorage.getItem("okta-token-storage");

  if (oktaToken == "{}") {
    window.location = "/logout";
  }

  return oktaToken ? true : false;
};

// this one is for local config
// export const oktaConfig = {
//   clientId: OKTA_CLIENT_ID,
//   issuer: `${OKTA_DOMAIN}/oauth2/default`,
//   redirectUri: window.location.origin + "/login/callback",
//   scopes: ["openid", "profile", "email", "app-role"],
//   // scopes: ["openid", "profile", "email", "app-role"],
//   pkce: true,
//   disableHttpsCheck: true,
// };

// export const isAdmin = () => {
//   const oktaToken = localStorage.getItem("okta-token-storage");

//   const { accessToken } = JSON.parse(oktaToken);

//   let isAdmin = null;

//   isAdmin = accessToken.claims?.role?.includes("jarvis-admin") ?? false;

//   if (oktaToken) {
//     fetch('http://localhost:8000/add', {
//       method: 'GET',
//       headers: {
//         'Accept': 'application/json',
//       },
//       credentials: 'include'
//     })
//     .then(response => response.json())
//     .then(data => console.log(data))
//     .catch(error => console.error('Error:', error));
//   }
//   return isAdmin;
// };

// for dev config
export const oktaConfig = {
  clientId: OKTA_CLIENT_ID,
  issuer: `${OKTA_DOMAIN}`,
  redirectUri: window.location.origin + "/login/callback",
  scopes: ["openid", "profile", "email", "groups"],
  pkce: true,
  disableHttpsCheck: true,
};

export const isAdmin = () => {
  const oktaToken = localStorage.getItem("okta-token-storage");

  const { idToken } = JSON.parse(oktaToken);
  const isAdmin = idToken.claims?.groups?.includes("jarvis-admin") ?? false;

  if (oktaToken) {
    fetch( `${process.env.REACT_APP_API_URL}/add`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      credentials: 'include'
    })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error('Error:', error));
  }
  return isAdmin;
};
