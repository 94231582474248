//Sample pages (TODO: Delete later)

import Projects from "./containers/MainContent/ecommerce/projects";

import Uialerts from "./containers/MainContent/ui/ui-alerts";
import Uibuttons from "./containers/MainContent/ui/ui-buttons";
import Uicards from "./containers/MainContent/ui/ui-cards";
import Uicarousel from "./containers/MainContent/ui/ui-carousel";
import Uigeneral from "./containers/MainContent/ui/ui-general";
import Uigrid from "./containers/MainContent/ui/ui-grid";
import Uiimages from "./containers/MainContent/ui/ui-images";
import Uilightbox from "./containers/MainContent/ui/ui-lightbox";
import Uimodals from "./containers/MainContent/ui/ui-modals";
import Uirangeslider from "./containers/MainContent/ui/ui-rangeslider";
import Uisweetalert from "./containers/MainContent/ui/ui-sweet-alert";
import Uitabsaccordions from "./containers/MainContent/ui/ui-tabs-accordions";
import Uitypography from "./containers/MainContent/ui/ui-typography";

import Pageslogin2 from "./containers/MainContent/pages/pages-login-2";
import Pagesregister2 from "./containers/MainContent/pages/pages-register-2";
import Pagesrecoverpw2 from "./containers/MainContent/pages/pages-recoverpw-2";
import pageslockscreen2 from "./containers/MainContent/pages/pages-lock-screen-2";

import Pageslogin from "./containers/MainContent/Auth/Login";
import Logout from "./containers/MainContent/Auth/Logout";

import Pagesblank from "./containers/MainContent/pages/pages-blank";
import Pagestimeline from "./containers/MainContent/pages/pages-timeline";
import Pagesinvoice from "./containers/MainContent/pages/pages-invoice";
import Pagespricing from "./containers/MainContent/pages/pages-pricing";
import Pagesmaintenance from "./containers/MainContent/pages/pages-maintenance";
import Pagesfaq from "./containers/MainContent/pages/pages-faq";
import Pages404 from "./containers/MainContent/pages/pages-404";
import Pages500 from "./containers/MainContent/pages/pages-500";

import Formelements from "./containers/MainContent/form/form-elements";
import Formvalidation from "./containers/MainContent/form/form-validation";
import Formadvanced from "./containers/MainContent/form/form-advanced";
import Formeditors from "./containers/MainContent/form/form-editors";
import Formuploads from "./containers/MainContent/form/form-uploads";
import Formxeditable from "./containers/MainContent/form/form-xeditable";
import Formwizard from "./containers/MainContent/form/form-wizard";
import Formmask from "./containers/MainContent/form/form-mask";

import Tablebasic from "./containers/MainContent/tables/tables-basic";
import Tabledatatable from "./containers/MainContent/tables/tables-datatable";
import Tableresponsive from "./containers/MainContent/tables/tables-responsive";
import Tableseditable from "./containers/MainContent/tables/tables-editable";

import Iconsmaterial from "./containers/MainContent/icons/icons-material";
import Iconsfontawesome from "./containers/MainContent/icons/icons-fontawesome";
import Iconsion from "./containers/MainContent/icons/icons-ion";
import Iconsthemify from "./containers/MainContent/icons/icons-themify";
import Iconsdripicons from "./containers/MainContent/icons/icons-dripicons";

import Dashboard from "./containers/jarvis/new-dashboard";
// import Dashboard from './containers/jarvis/dashboard';

//JARVIS Quote Builder Pages
import QuoteBuilderSetup from "./containers/jarvis/quote-builder/quote-setup";
import QuoteBuilderItems from "./containers/jarvis/quote-builder/quote-items";
import QuoteBuilderRates from "./containers/jarvis/quote-builder/quote-rates";
import QuoteBuilderCustomiseProposal from "./containers/jarvis/quote-builder/customise-proposal";
import QuoteBuilderSendProposal from "./containers/jarvis/quote-builder/send-proposal";
import QuoteBuilderCustomiseCAF from "./containers/jarvis/quote-builder/customise-caf";
import QuoteBuilderBillingContactCAF from "./containers/jarvis/quote-builder/billing-contact-caf";
import QuoteBuilderPrefillCAF from "./containers/jarvis/quote-builder/prefill-caf";
import QuoteBuilderSendForSigning from "./containers/jarvis/quote-builder/send-for-signing";
import QuoteBuilderCustomerAccepted from "./containers/jarvis/quote-builder/customer-accepted";

import QuoteDisplayProposal from "./containers/MainContent/pages/pages-blank";

//JARVIS Quotes Pages
import MyQuotes from "./containers/jarvis/quotes/my-quotes";
import QuotesAll from "./containers/jarvis/quotes/quotes-all";
// import NewDeal from './containers/jarvis/quotes/new-deal';

//JARVIS Admin Pages
import ProductList from "./containers/jarvis/admin/products/product-list";
import ProductForm from "./containers/jarvis/admin/products/product-form";
import BundleList from "./containers/jarvis/admin/bundles/bundle-list";
import BundleForm from "./containers/jarvis/admin/bundles/bundle-form";
import CategoryList from "./containers/jarvis/admin/categories/category-list";
import CategoryForm from "./containers/jarvis/admin/categories/category-form";
import ProductTemplates from "./containers/jarvis/admin/products/product-templates";
import ZapierIntegration from "./containers/jarvis/admin/zapier-integration/zapier-integration";
import CAFFormBuilder from "./containers/jarvis/admin/caf-form-builder/caf-form-builder";
import CreateUser from "./containers/jarvis/admin/user-management/create-user";
import ManageUsers from "./containers/jarvis/admin/user-management/manage-users";
import ManageAgents from "./containers/jarvis/admin/agent-management/manage-agents";

import AdminCategories from "./containers/MainContent/pages/pages-blank";
import AdminBundles from "./containers/MainContent/pages/pages-blank";
import AdminQuoteTypes from "./containers/MainContent/pages/pages-blank";
import AdminConfig from "./containers/jarvis/admin/settings/admin-settings";

import ResetPassword from "./containers/jarvis/user/reset-password";

const routes = [
  //Refer links from Hubspot
  {
    path: "/deal/:pathArg/:quoteId",
    component: QuoteBuilderSetup,
    ispublic: true,
  },

  //Quote Builder Pages
  { path: "/quote-setup", component: QuoteBuilderSetup, ispublic: true },
  {
    path: "/quote-setup/:pathArg",
    component: QuoteBuilderSetup,
    ispublic: true,
  },
  {
    path: "/quote-items/:pathArg",
    component: QuoteBuilderItems,
    ispublic: true,
  },
  {
    path: "/quote-rates/:pathArg",
    component: QuoteBuilderRates,
    ispublic: true,
  },
  {
    path: "/customise-proposal/:pathArg",
    component: QuoteBuilderCustomiseProposal,
  },
  { path: "/send-proposal/:pathArg", component: QuoteBuilderSendProposal },
  { path: "/customise-caf/:pathArg", component: QuoteBuilderCustomiseCAF },
  {
    path: "/billing-contact-caf/:pathArg",
    component: QuoteBuilderBillingContactCAF,
  },
  { path: "/prefill-caf/:pathArg", component: QuoteBuilderPrefillCAF },
  { path: "/send-for-signing/:pathArg", component: QuoteBuilderSendForSigning },
  {
    path: "/customer-accepted/:pathArg",
    component: QuoteBuilderCustomerAccepted,
  },

  {
    path: "/display-quote/:pathArg",
    component: QuoteDisplayProposal,
    ispublic: true,
  },

  //Quotes Pages
  { path: "/my-quotes", component: MyQuotes },
  { path: "/quotes", component: QuotesAll },
  // { path: '/new-deal', component: NewDeal },

  //Admin pages
  { path: "/admin/products", component: ProductList, forAdmin: true },
  { path: "/admin/product/create", component: ProductForm, forAdmin: true },
  {
    path: "/admin/product/edit/:pathArg",
    component: ProductForm,
    forAdmin: true,
  },
  {
    path: "/admin/product/templates/:pathArg",
    component: ProductTemplates,
    forAdmin: true,
  },
  {
    path: "/admin/templates/:pathArg",
    component: ProductTemplates,
    forAdmin: true,
  },

  { path: "/admin/bundles", component: BundleList, forAdmin: true },
  { path: "/admin/bundle/create", component: BundleForm, forAdmin: true },
  {
    path: "/admin/bundle/edit/:pathArg",
    component: BundleForm,
    forAdmin: true,
  },

  { path: "/admin/categories", component: CategoryList, forAdmin: true },
  { path: "/admin/category/create", component: CategoryForm, forAdmin: true },
  {
    path: "/admin/category/edit/:pathArg",
    component: CategoryForm,
    forAdmin: true,
  },

  {
    path: "/admin/zapier-integration",
    component: ZapierIntegration,
    forAdmin: true,
  },

  {
    path: "/admin/caf-form-builder",
    component: CAFFormBuilder,
    forAdmin: true,
  },

  { path: "/admin-categories", component: AdminCategories, forAdmin: true },
  { path: "/admin/admin-bundles", component: AdminBundles, forAdmin: true },
  {
    path: "/admin/admin-quotetypes",
    component: AdminQuoteTypes,
    forAdmin: true,
  },
  { path: "/admin/admin-config", component: AdminConfig, forAdmin: true },

  { path: "/admin/create-user", component: CreateUser, forAdmin: true },
  { path: '/admin/manage-users', component: ManageUsers, forAdmin: true },
  { path: "/admin/manage-agents", component: ManageAgents, forAdmin: true },

  { path: "/user/reset-password", component: ResetPassword },

  //Login landing + logout page
  { path: "/login", component: Pageslogin, ispublic: true },
  { path: "/logout", component: Logout, ispublic: true },

  { path: "/projects", component: Projects },

  { path: "/pages-login-2", component: Pageslogin2 },
  { path: "/pages-register-2", component: Pagesregister2 },
  { path: "/pages-recoverpw-2", component: Pagesrecoverpw2 },
  { path: "/pages-lock-screen-2", component: pageslockscreen2 },

  { path: "/pages-blank", component: Pagesblank },
  { path: "/pages-timeline", component: Pagestimeline },
  { path: "/pages-invoice", component: Pagesinvoice },
  { path: "/pages-pricing", component: Pagespricing },
  { path: "/pages-maintenance", component: Pagesmaintenance },
  { path: "/pages-faq", component: Pagesfaq },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  { path: "/ui-alerts", component: Uialerts },
  { path: "/ui-buttons", component: Uibuttons },
  { path: "/ui-cards", component: Uicards },
  { path: "/ui-carousel", component: Uicarousel },
  { path: "/ui-general", component: Uigeneral },
  { path: "/ui-grid", component: Uigrid },
  { path: "/ui-images", component: Uiimages },
  { path: "/ui-lightbox", component: Uilightbox },
  { path: "/ui-modals", component: Uimodals },
  { path: "/ui-rangeslider", component: Uirangeslider },
  { path: "/ui-sweet-alert", component: Uisweetalert },
  { path: "/ui-tabs-accordions", component: Uitabsaccordions },
  { path: "/ui-typography", component: Uitypography },

  { path: "/form-elements", component: Formelements },
  { path: "/form-validation", component: Formvalidation },
  { path: "/form-advanced", component: Formadvanced },
  { path: "/form-editors", component: Formeditors },
  { path: "/form-uploads", component: Formuploads },
  { path: "/form-xeditable", component: Formxeditable },
  { path: "/form-wizard", component: Formwizard },
  { path: "/form-mask", component: Formmask },

  { path: "/tables-basic", component: Tablebasic },
  { path: "/tables-datatable", component: Tabledatatable },
  { path: "/tables-responsive", component: Tableresponsive },
  { path: "/tables-editable", component: Tableseditable },

  { path: "/icons-material", component: Iconsmaterial },
  { path: "/icons-fontawesome", component: Iconsfontawesome },
  { path: "/icons-ion", component: Iconsion },
  { path: "/icons-themify", component: Iconsthemify },
  { path: "/icons-dripicons", component: Iconsdripicons },

  { path: "/layout-collapsed-sidebar", component: Dashboard },
  { path: "/layout-light-sidebar", component: Dashboard },

  { path: "/dashboard", component: Dashboard },
  { path: "/", component: Pageslogin },
];

export default routes;
