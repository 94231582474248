import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Label, Input } from 'reactstrap';
import Select from 'react-select';
import { activateAuthLayout } from '../../../store/actions';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getQuoteBuilderURL } from '../../../helpers/common';
import { useHistory } from 'react-router';

/**
 * graphQL query for all the data needed for customise proposal UI
 * Quote - quote based from the passed quote id in the URL
 **/
const QUOTE = gql`
  query getQuote($HubSpotDealId: String, $QuoteId: ID) {
    Quote(HubSpotDealId: $HubSpotDealId, QuoteId: $QuoteId) {
      QuoteId
      ContactFirstname
      ContactSurname
      ContactEmail
      XeroContactID
      HubSpotDealId
      Description
      HubSpotDealOwner
      HubSpotDealValue
      Description
      ProposalTitle
      ValidUntil
      CustomerLogo
      CustomerOctaneId
      WorkflowStep
      AcceptedQuote
      PortalId
      Status
      AgentId
      ContactFirstname
      ContactSurname
      ContactEmail
    }

    XeroContactsQuery {
      ContactID
      AccountNumber
      Name
      FirstName
      LastName
      EmailAddress
    }
  }
`;

const QUOTE_TYPES = gql`
  query QuoteTypes {
    QuoteTypes {
      QuoteTypeId
      Name
      Description
      Active
    }
  }
`;

const UPDATE_QUOTE = gql`
  mutation updateQuote($QuoteInput: QuoteInput) {
    updateQuote(input: $QuoteInput) {
      Description
      HubSpotDealValue
      PrimaryContact
      ContactFirstname
      ContactSurname
      ContactEmail
      CustomerLogo
      QuoteTypeId1
      QuoteTypeId2
      QuoteContractMonths1
      QuoteContractMonths2
      Description
      WorkflowStep
      AcceptedQuote
      CustomerOctaneId
      Status
      AgentId
      InternalSalesId
      TeamId
      XeroContactID
    }
  }
`;

const QuoteBuilderBillingContactCAF = (props) => {
  props.activateAuthLayout();

  let history = useHistory();

  const quoteIDParam = history.location.pathname.split('/').pop();

  // initialize states
  const [stepError, setStepError] = useState(null);
  const [previous, setPrevious] = useState(false);
  const [disableQuote, setDisableQuote] = useState(false);
  const [quoteTypes, setQuoteTypes] = useState(null);
  const [showStepError, setShowStepError] = useState(false);

  const [selectedXeroContact, setSelectedXeroContact] = useState(null);
  const [xeroContacts, setXeroContacts] = useState([]);
  const [xeroError, setXeroError] = useState(false);
  const [init, setInit] = useState(false);

  const [customerOctaneId, setCustomerOctaneId] = useState('');

  // initialize apollo graphql queries and mutations
  const [updateQuote, { loading: updateLoading, data: updateData }] =
    useMutation(UPDATE_QUOTE);
  const { data: quoteTypesData } = useQuery(QUOTE_TYPES);
  const { data: existingQuoteData } = useQuery(QUOTE, {
    variables: { QuoteId: props.pathArg },
  });

  /**
   * useEffect() - function equivalent to componentDidMount
   **/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // check first if quote exist and then create if there is none
    if (existingQuoteData && init === false) {
      // redirect to the current workflow step
      if (
        existingQuoteData.Quote &&
        existingQuoteData.Quote.AcceptedQuote &&
        existingQuoteData.Quote.WorkflowStep == 'CUSTOMER_ACCEPTED'
      ) {
        existingQuoteData.Quote &&
          existingQuoteData.Quote.WorkflowStep !== 'BILLING_CONTACT_CAF' &&
          window.location.replace(
            getQuoteBuilderURL(
              existingQuoteData.Quote.WorkflowStep,
              existingQuoteData.Quote.QuoteId,
              existingQuoteData.Quote.HubSpotDealId
            )
          );
      }

      if (xeroContacts && !xeroContacts.length) {
        setXeroContacts(existingQuoteData.XeroContactsQuery);

        existingQuoteData.XeroContactsQuery.forEach((indiv_xero_contact) => {
          if (indiv_xero_contact.ContactID === 'error' && !xeroError) {
            setXeroError(true);
            return;
          }

          if (
            existingQuoteData &&
            existingQuoteData.Quote &&
            existingQuoteData.Quote.XeroContactID == 0
          ) {
            setSelectedXeroContact({
              value: 0,
              label: 'Create new Xero contact...',
            });
          } else if (
            existingQuoteData &&
            existingQuoteData.Quote &&
            existingQuoteData.Quote.XeroContactID ===
              indiv_xero_contact.ContactID
          ) {
            setSelectedXeroContact({
              value: indiv_xero_contact.ContactID,
              label:
                indiv_xero_contact.Name +
                processXeroGivenName(indiv_xero_contact),
            });
          }
        });
      }
    }

    if (existingQuoteData && init === false) {
      // disable screen when status is accepted, declined or voided
      const status = existingQuoteData.Quote
        ? existingQuoteData.Quote.Status
        : 'in_progress';
      setDisableQuote(
        status === 'accepted' || status === 'declined' || status === 'voided'
          ? true
          : false
      );

      setInit(true);
    }

    // get the quote types data and remove quote type 0 - cost price
    if (quoteTypesData && quoteTypes === null) {
      setQuoteTypes(Array.from(quoteTypesData.QuoteTypes).slice(1));
    }

    // set the current contract periods of the quote
    if (existingQuoteData && existingQuoteData.Quote && init === false) {
      setCustomerOctaneId(
        existingQuoteData && existingQuoteData.Quote
          ? existingQuoteData.Quote.CustomerOctaneId
          : ''
      );
    }
  }, [
    props,
    init,
    quoteTypes,
    quoteTypesData,
    existingQuoteData,
    history,
    quoteIDParam,
  ]);

  const goToPreviousStep = (e) => {
    setPrevious(true);
    e.preventDefault();
    updateQuote({
      variables: {
        QuoteInput: {
          QuoteId: existingQuoteData.QuoteId,
          HubSpotDealId: existingQuoteData.HubSpotDealId,
          WorkflowStep: existingQuoteData.PreviousWorkflowStep
            ? existingQuoteData.PreviousWorkflowStep
            : 'SEND_FOR_SIGNING',
          Status: 'viewed',
        },
      },
    });
  };

  const proceedNextStep = (e) => {
    //field validation
    if (!selectedXeroContact) {
      setStepError({
        error: true,
        message: 'Please select a Xero Contact',
      });
    } else {
      setShowStepError(false);
      updateQuote({
        variables: {
          QuoteInput: {
            QuoteId: quoteIDParam,
            CustomerOctaneId: customerOctaneId
              ? customerOctaneId
              : existingQuoteData.Quote.CustomerOctaneId,
            WorkflowStep: 'CUSTOMISE_CAF',
            XeroContactID: selectedXeroContact
              ? selectedXeroContact.value + ""
              : null,
          },
        },
      });
    }
  };

  const processXeroGivenName = (args) => {
    var given_name = '';

    if (args.LastName && args.FirstName) {
      given_name = ' (' + args.FirstName + ' ' + args.LastName + ')';
    }

    return given_name;
  };

  const changeXeroContactID = (e) => {
    const { value = 0 } = e || {};
    if (value) {
      let xeroDetails = xeroContacts.filter((item) => {
        return item.ContactID === value;
      })[0];

      if (Object.keys(xeroDetails).length > 0) {
        const { AccountNumber = '' } = xeroDetails || {};
        setCustomerOctaneId(AccountNumber);
      }
    } else {
      setCustomerOctaneId('');
    }

    setSelectedXeroContact(e);
  };
  console.log('xeroContacts',xeroContacts)
  return (
    <React.Fragment>
      {disableQuote && <div className="overlay"></div>}
      {stepError && stepError.error === true && (
        <SweetAlert
          error
          title={<span style={{ fontSize: '24px' }}>Error</span>}
          onConfirm={(e) => setStepError({ error: false, message: '' })}
        >
          {stepError.message}
        </SweetAlert>
      )}

      {updateLoading && (
        <SweetAlert
          info
          title={<span style={{ fontSize: '24px' }}>Updating Quote</span>}
          onConfirm={(e) => console.log('loading..')}
          showConfirm={false}
        >
          Saving Quote Data..
        </SweetAlert>
      )}

      {updateData && showStepError === false && stepError === null && (
        <SweetAlert
          success
          title={<span style={{ fontSize: '24px' }}>Success</span>}
          onConfirm={(e) =>
            window.location.replace(
              `/customise-caf/${existingQuoteData.Quote.QuoteId}`
            )
          }
          showConfirm={false}
          timeout={2000}
        >
          Quote setup step complete. Proceeding to next step.
        </SweetAlert>
      )}

      {
        // load live search and quote item builder UI when quote with the ID passed on the URL exists
        existingQuoteData && existingQuoteData.Quote && (
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col sm="6">
                    <h4 className="page-title">{`$ ${existingQuoteData.Quote.HubSpotDealValue} - ${existingQuoteData.Quote.Description}  - Billing Contact CAF - JARVIS`}</h4>
                  </Col>
                </Row>
              </div>

              <Card>
                <CardBody>
                  <Row>
                    <Col lg="12">
                      <Card>
                        <CardBody id="deal_details_section">
                          <h4 className="mt-0 header-title">
                            BILLING CONTACT CAF
                          </h4>
                          <div>
                            <table className="table mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row">
                                    Xero Contact{' '}
                                    <span className="text-danger">*</span>
                                  </th>
                                  <td align="right">
                                    {Boolean(xeroContacts && !xeroError) ? (
                                      <Select
                                        options={[
                                          {
                                            value: 0,
                                            label: 'Create new Xero contact...',
                                          },
                                          ...xeroContacts.map((data) => {
                                            return {
                                              value: data.ContactID,
                                              label:
                                                data.Name +
                                                processXeroGivenName(data),
                                            };
                                          }),
                                        ]}
                                        value={selectedXeroContact}
                                        placeholder="Please Select..."
                                        styles={{
                                          option: (styles) => ({
                                            ...styles,
                                            textAlign: 'left',
                                          }),
                                        }}
                                        onChange={changeXeroContactID}
                                      />
                                    ) : (
                                      <span className="text-danger">
                                        Something went wrong with Xero (Please
                                        authenticate)
                                      </span>
                                    )}
                                  </td>
                                </tr>

                                <tr>
                                  <th scope="row">Customer Octane ID </th>
                                  <td align="right">
                                    <Input
                                      type="text"
                                      defaultValue={customerOctaneId}
                                      onChange={(e) =>
                                        setCustomerOctaneId(e.target.value)
                                      }
                                      readOnly={
                                        selectedXeroContact &&
                                        selectedXeroContact.value &&
                                        customerOctaneId
                                          ? true
                                          : false
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div align="right">
                            <br />
                            <br />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <button
                        className="btn-icon btn btn-primary btn-lg float-right"
                        onClick={proceedNextStep}
                      >
                        {' '}
                        <span className="btn-icon-label">
                          <i className="ion ion-md-arrow-forward mr-2"></i>
                        </span>{' '}
                        Next Step
                      </button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
    </React.Fragment>
  );
};

export default connect(null, { activateAuthLayout })(
  QuoteBuilderBillingContactCAF
);
