import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import { checkLogin, clearErrorLogin, clearError } from '../../../store/actions';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useOktaAuth } from "@okta/okta-react";

const Pageslogin = ({ ...props }) => {
  const { oktaAuth, authState } = useOktaAuth();

  const [forgotPassword, setForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postError, setPostError] = useState("");
  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .max(50, "Maximum 50 symbols")
      .required("Username is required"),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required"),
  });
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const login = async () => {
    // await oktaAuth.signInWithRedirect({ originalUri: "/dashboard" });
    await oktaAuth.signInWithRedirect();
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  // useEffect(() => {
  //   login();
  // }, []);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting, setErrors }) => {
      enableLoading();
      let request_body = JSON.stringify({
        username: values.username,
        password: values.password,
      });
      setTimeout(async () => {
        const x = "http://localhost:8000";
        await fetch(x + "/login", {
          method: "POST",
          body: request_body,
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((req) => {
            return req.json();
          })
          .then(({ authToken, error }) => {
            if (authToken) {
              window.location.href = "/dashboard";
            } else {
              disableLoading();
              setErrors({
                username: "Please check username",
                password: "Please check password",
              });
              setPostError(error);
            }
          })
          .catch((err) => {
            disableLoading();
            console.log(err);
          });
      }, 1000);
    },
  });

  const forgotPasswordFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting, setErrors }) => {
      enableLoading();
      let request_body = JSON.stringify({ email: values.email });
      setTimeout(async () => {
        const x = "/api";
        await fetch(x + "/forgot-password", {
          method: "POST",
          body: request_body,
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((req) => {
            return req.json();
          })
          .then(({ status, message }) => {
            if (status === 200) {
              setForgotPassword(false);
            } else {
              setErrors({ email: "Please check email" });
            }
            setPostError(message);
            disableLoading();
          })
          .catch((err) => {
            disableLoading();
            console.log(err);
          });
      }, 1000);
    },
  });

  return (
    <div
      style={{
        height: "100vh",
        background: "url('assets/images/bg1.jpg')",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <img
        src="/assets/images/Switch Logo Reverse Tagline RGB.png"
        style={{
          maxHeight: "15vh",
          width: "15%",
          padding: "2vh",
          position: "absolute",
        }}
      />
      <div className="row h-100">
        <div className="offset-8 col-4 d-flex align-items-end justify-content-center">
          {forgotPassword ? (
            <div className="w-50">
              {/* begin::Head */}
              <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1 text-white">Forgot Password</h3>
              </div>
              {/* end::Head */}

              {/*begin::Form*/}
              <form
                onSubmit={forgotPasswordFormik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                {forgotPasswordFormik.status && (
                  <div className="mb-10 alert alert-custom alert-light-primary alert-dismissible">
                    <div className="alert-text font-weight-bold text-center">
                      {forgotPasswordFormik.status}
                    </div>
                  </div>
                )}

                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Enter email to send the new password"
                    className={`form-control form-control-solid h-auto py-4 px-3 ${getInputClasses(
                      "email"
                    )}`}
                    name="email"
                    {...forgotPasswordFormik.getFieldProps("email")}
                  />
                  {forgotPasswordFormik.touched.email &&
                  forgotPasswordFormik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {forgotPasswordFormik.errors.email}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                  <button
                    type="button"
                    className="text-dark-50 my-3 mr-2 btn text-primary"
                    onClick={() => {
                      setForgotPassword(false);
                    }}
                  >
                    Login Account
                  </button>
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={loading}
                    className={`btn btn-outline-primary font-weight-bold px-4 py-3 my-3`}
                  >
                    {loading ? (
                      <div
                        className="spinner-border text-primary mx-auto"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <span>Send</span>
                    )}
                  </button>
                </div>
              </form>
              {/*end::Form*/}
            </div>
          ) : (
            <div className="w-50">
              {/* begin::Head */}
              <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1 text-white">Login Account</h3>
                <div className="text-muted font-weight-bold">
                  {postError ? (
                    <p className="text-danger">{postError}</p>
                  ) : (
                    "Enter your username and password"
                  )}
                </div>
              </div>
              {/* end::Head */}

              {/*begin::Form*/}
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                {formik.status && (
                  <div className="mb-10 alert alert-custom alert-light-primary alert-dismissible">
                    <div className="alert-text font-weight-bold text-center">
                      {formik.status}
                    </div>
                  </div>
                )}

                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Username"
                    type="username"
                    className={`form-control form-control-solid h-auto py-4 px-3 ${getInputClasses(
                      "username"
                    )}`}
                    name="username"
                    {...formik.getFieldProps("username")}
                  />
                  {formik.touched.username && formik.errors.username ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.username}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-4 px-3 ${getInputClasses(
                      "password"
                    )}`}
                    name="password"
                    {...formik.getFieldProps("password")}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.password}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                  <button
                    type="button"
                    className="text-dark-50 my-3 mr-2 btn text-primary"
                    onClick={() => {
                      setForgotPassword(true);
                    }}
                  >
                    Forgot Password
                  </button>
                  <button
                    id="kt_login_signin_submit"
                    type="button"
                    disabled={loading}
                    onClick={login}
                    className={`btn btn-outline-primary font-weight-bold px-4 py-3 my-3`}
                  >
                    {authState ? <span>Login</span> : <span>Logout</span>}
                  </button>
                  {/* <button
                    id="kt_login_signin_submit"
                    type="button"
                    disabled={loading}
                    onClick={login}
                    className={`btn btn-outline-primary font-weight-bold px-4 py-3 my-3`}
                  >
                    {loading ? (
                      <div
                        className="spinner-border text-primary mx-auto"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <span>Login</span>
                    )}
                  </button> */}
                </div>
              </form>
              {/*end::Form*/}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(connect()(Pageslogin));
