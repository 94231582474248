import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter } from "react-router-dom";

// users
import user from "../../../images/user-icon.png";
import { useOktaAuth } from "@okta/okta-react";

// rewrite class component to functional component with hooks
const ProfileMenu = (props) => {
  const [menu, setMenu] = React.useState(false);
  const toggle = () => setMenu(!menu);

  const { oktaAuth, authState } = useOktaAuth();

  const logout = async () => {
    sessionStorage.setItem("user", null);
    await oktaAuth.signOut();
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="notification-list list-inline-item nav-pro-img"
        tag="li"
      >
        <DropdownToggle className="nav-link arrow-none nav-user" tag="a">
          <img src={user} alt="user" className="rounded-circle" />
        </DropdownToggle>
        <DropdownMenu className="profile-dropdown" right>
          {/* <DropdownItem tag="a" href="/user/reset-password">
            <i className="mdi mdi-account-circle m-r-5"></i> Reset Password
          </DropdownItem> */}
          <DropdownItem tag="button" className="text-danger" onClick={logout}>
            <i className="mdi mdi-power text-danger"></i> Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};
// class ProfileMenu extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             menu: false,
//         };
//         this.toggle = this.toggle.bind(this);
//     }

//     toggle() {
//         this.setState(prevState => ({
//             menu: !prevState.menu
//         }));
//     }

//     render() {

//         return (
//             <React.Fragment>
//                 <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="notification-list list-inline-item nav-pro-img" tag="li">
//                     <DropdownToggle className="nav-link arrow-none nav-user" tag="a">
//                         <img src={user} alt="user" className="rounded-circle" />
//                     </DropdownToggle>
//                     <DropdownMenu className="profile-dropdown" right>
//                         <DropdownItem tag="a" href="/user/reset-password"><i className="mdi mdi-account-circle m-r-5"></i> Reset Password</DropdownItem>
//                         <DropdownItem tag="a"  className="text-danger" href="/logout"><i className="mdi mdi-power text-danger"></i> Logout</DropdownItem>
//                     </DropdownMenu>
//                 </Dropdown>
//             </React.Fragment>
//         );
//     }
// }

export default withRouter(ProfileMenu);
