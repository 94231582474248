import { useOktaAuth } from "@okta/okta-react";

export const getOktaUser = () => {
  const { oktaAuth } = useOktaAuth;
  // console.log("oktaAuth");
  // console.log(oktaAuth);
  //   oktaAuth.token.getUserInfo().then((info) => {
  //     console.log(info);
  //   });
};
