import React, { Component, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import MetisMenu from "metismenujs";
import { connect } from "react-redux";
import { toggleSidebar } from "../../store/actions";
import Swal from "sweetalert2";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useHistory, useLocation } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";

import env from "../../env";
import { isAdmin, isAuthenticated } from "../../lib/oktaConfig";

const QUOTE = gql`
  query getCurrentQuote($HubSpotDealId: String, $QuoteId: ID) {
    Quote(HubSpotDealId: $HubSpotDealId, QuoteId: $QuoteId) {
      QuoteId
      HubSpotDealId
      Description
      HubSpotDealOwner
      HubSpotDealValue
      HubSpotDealCreated
      CompanyName
      PrimaryContact
      ContactFirstname
      ContactSurname
      ContactEmail
      Created
      CreatedBy
      Updated
      UpdatedBy
      Status
      XeroQuoteXID
      QuoteTypeId1
      QuoteTypeId2
      QuoteContractMonths1
      QuoteContractMonths2
      Quote1DiscountMonthly
      Quote1DiscountTypeMonthly
      Quote1DiscountHardware
      Quote1DiscountTypeHardware
      Quote1DiscountSetup
      Quote1DiscountTypeSetup
      Quote2DiscountMonthly
      Quote2DiscountTypeMonthly
      Quote2DiscountHardware
      Quote2DiscountTypeHardware
      Quote2DiscountSetup
      Quote2DiscountTypeSetup
      QuoteType1QantasBonus
      QuoteType2QantasBonus
      WorkflowStep
      PreviousWorkflowStep
      Status
      AcceptedQuote
      QuoteVersions {
        VersionId
        Token
        File
        Timestamp
      }
    }
    Enums(type: "WorkflowStep") {
      data
    }
  }
`;

const UPDATE_QUOTE = gql`
  mutation updateQuote($QuoteInput: QuoteInput) {
    updateQuote(input: $QuoteInput) {
      QuoteId
      HubSpotDealId
      WorkflowStep
      PreviousWorkflowStep
    }
  }
`;

const CREATE_NEW_QUOTE = gql`
  mutation createNewQuote($CreateNewQuoteInput: CreateNewQuoteInput) {
    createNewQuote(input: $CreateNewQuoteInput) {
      status
      message
      quote {
        QuoteId
        HubSpotDealId
      }
    }
  }
`;

const SideNav = ({ user }) => {
  const location = useLocation();
  const history = useHistory();
  const urlArr = location.pathname.split("/");
  const queryVariable = location.pathname.match(/deal\/[\d]+/g)
    ? {
        HubSpotDealId: urlArr[urlArr.length - 2],
        QuoteId: urlArr[urlArr.length - 1],
      }
    : { QuoteId: location.pathname.split("/").pop() };
  const currentQuoteParam = location.search
    ? { QuoteId: location.search.split("=").pop() }
    : null;
  const { data: currentQuoteData, refetch } = useQuery(QUOTE, {
    variables: currentQuoteParam ? currentQuoteParam : queryVariable,
  });
  const [updateQuote, { data: updatedQuoteData }] = useMutation(UPDATE_QUOTE);
  const [createNewQuote] = useMutation(CREATE_NEW_QUOTE);

  const [workflowLink, setWorkflowLink] = useState(null);
  const [stepError, setStepError] = useState(null);
  const [disableQuote, setDisableQuote] = useState(false);
  const [disableQuoteInitial, setDisableQuoteInitial] = useState(false);
  const [disabledSteps, setDisabledSteps] = useState([]);
  const [previewUrl, setPreviewUrl] = useState("");
  const [initSteps, setInitSteps] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (currentQuoteData && currentQuoteData.Quote === null) {
      refetch();
    }
    if (updatedQuoteData && workflowLink !== null) {
      if (workflowLink.includes("caf")) window.location.replace(workflowLink);
      else history.replace(workflowLink);
    }

    if (currentQuoteData && currentQuoteData.Quote) {
      // disable screen when status is accepted, declined or voided
      const status = currentQuoteData.Quote.Status;
      const currentStep = currentQuoteData.Quote.WorkflowStep || "";
      const flowSteps = [
        "QUOTE_SETUP",
        "QUOTE_ITEMS",
        "QUOTE_RATES",
        "CUSTOMISE_PROPOSAL",
        "SEND_PROPOSAL",
      ];
      const acceptedQuote = currentQuoteData.Quote.AcceptedQuote || null;
      setDisableQuote(
        status === "accepted" || status === "declined" ? true : false
      );
      // setDisableQuoteInitial(
      //   status === 'initial_acceptance' && acceptedQuote !== null ? true : false
      // );
      const { QuoteVersions = [] } = currentQuoteData.Quote;
      disableWorkflowSteps();
      if (QuoteVersions && flowSteps.indexOf(currentStep) < 0) {
        QuoteVersions &&
        Array.isArray(QuoteVersions) &&
        QuoteVersions.length > 0
          ? setPreviewUrl(
              `${env.CUSTOMER_FACING_PROPOSAL_BASE_URL}${
                QuoteVersions[QuoteVersions.length - 1].Token
              }`
            )
          : setPreviewUrl(
              `${env.CUSTOMER_FACING_PROPOSAL_BASE_URL}${currentQuoteData.Quote.Token}`
            );
      } else {
        if (
          currentQuoteData.Quote &&
          currentQuoteData.Quote.AcceptedQuote &&
          QuoteVersions
        ) {
          QuoteVersions &&
          Array.isArray(QuoteVersions) &&
          QuoteVersions.length > 0
            ? setPreviewUrl(
                `${env.CUSTOMER_FACING_PROPOSAL_BASE_URL}${
                  QuoteVersions[QuoteVersions.length - 1].Token
                }`
              )
            : setPreviewUrl(
                `${env.CUSTOMER_FACING_PROPOSAL_BASE_URL}${currentQuoteData.Quote.Token}`
              );
        } else {
          setPreviewUrl("");
        }
      }
    }
  });

  const goToWorkflowStep = (e, workflowStep, link) => {
    e.preventDefault();
    setWorkflowLink(null);
    const currentQuote = currentQuoteData.Quote;
    if (!disableQuote) {
      if (location.pathname !== link) {
        if (
          workflowStep === "CUSTOMISE_CAF" ||
          workflowStep === "PREFILL_CAF" ||
          workflowStep === "BILLING_CONTACT_CAF"
        ) {
          const previousStep =
            currentQuote.WorkflowStep === "CUSTOMISE_CAF" ||
            currentQuote.WorkflowStep === "PREFILL_CAF"
              ? currentQuote.PreviousWorkflowStep
              : currentQuote.WorkflowStep;
          updateWorkflowStep(workflowStep, link, previousStep);
        } else {
          updateWorkflowStep(workflowStep, link);
        }

        //   if (workflowStep === 'QUOTE_SETUP' && !disableQuoteInitial) {
        //     updateWorkflowStep(workflowStep, link);
        //   } else if (workflowStep === 'QUOTE_ITEMS' && !disableQuoteInitial) {
        //     if (currentQuote.WorkflowStep !== 'QUOTE_SETUP') {
        //       if (disabledSteps.indexOf('QUOTE_ITEMS') === -1)
        //         updateWorkflowStep(workflowStep, link);
        //     }
        //   } else if (workflowStep === 'QUOTE_RATES' && !disableQuoteInitial) {
        //     if (
        //       currentQuote.WorkflowStep !== 'QUOTE_ITEMS' &&
        //       currentQuote.WorkflowStep !== 'QUOTE_SETUP'
        //     ) {
        //       if (disabledSteps.indexOf('QUOTE_RATES') === -1)
        //         updateWorkflowStep(workflowStep, link);
        //     }
        //   } else if (
        //     workflowStep === 'CUSTOMISE_PROPOSAL' &&
        //     !disableQuoteInitial
        //   ) {
        //     if (
        //       currentQuote.WorkflowStep === 'CUSTOMISE_PROPOSAL' ||
        //       currentQuote.WorkflowStep === 'SEND_PROPOSAL' ||
        //       currentQuote.WorkflowStep === 'CUSTOMISE_CAF'
        //     ) {
        //       if (disabledSteps.indexOf('CUSTOMISE_PROPOSAL') === -1)
        //         updateWorkflowStep(workflowStep, link);
        //     }
        //   } else if (workflowStep === 'SEND_PROPOSAL' && !disableQuoteInitial) {
        //     if (
        //       currentQuote.WorkflowStep === 'SEND_PROPOSAL' ||
        //       currentQuote.WorkflowStep === 'CUSTOMISE_CAF' ||
        //       currentQuote.WorkflowStep === 'PREFILL_CAF'
        //     ) {
        //       if (disabledSteps.indexOf('SEND_PROPOSAL') === -1)
        //         updateWorkflowStep(workflowStep, link);
        //     }
        //   } else if (
        //     workflowStep === 'CUSTOMISE_CAF' ||
        //     workflowStep === 'PREFILL_CAF' ||
        //     workflowStep === 'BILLING_CONTACT_CAF'
        //   ) {
        //     const previousStep =
        //       currentQuote.WorkflowStep === 'CUSTOMISE_CAF' ||
        //       currentQuote.WorkflowStep === 'PREFILL_CAF'
        //         ? currentQuote.PreviousWorkflowStep
        //         : currentQuote.WorkflowStep;
        //     updateWorkflowStep(workflowStep, link, previousStep);
        //   }
      }
    } else if (
      currentQuote &&
      currentQuote.WorkflowStep === "CUSTOMER_ACCEPTED" &&
      workflowStep === "CUSTOMER_ACCEPTED"
    ) {
      history.push(link);
    }
  };

  const updateWorkflowStep = (workflowStep, link, previousStep = null) => {
    updateQuote({
      variables: {
        QuoteInput: {
          QuoteId: currentQuoteData.Quote.QuoteId,
          HubSpotDealId: currentQuoteData.Quote.HubSpotDealId,
          WorkflowStep: workflowStep,
          PreviousWorkflowStep: previousStep ? previousStep : null,
        },
      },
    });
    setWorkflowLink(link);
  };

  const adminURL = (url) => {
    return currentQuoteParam &&
      currentQuoteParam.QuoteId &&
      Number.isInteger(parseInt(currentQuoteParam.QuoteId))
      ? `${url}?currentQuote=${currentQuoteParam.QuoteId}`
      : queryVariable &&
        queryVariable.QuoteId &&
        Number.isInteger(parseInt(queryVariable.QuoteId))
      ? `${url}?currentQuote=${queryVariable.QuoteId}`
      : url;
  };

  const disableWorkflowSteps = () => {
    const { Quote = {} } = currentQuoteData;
    if (Quote && Quote.AcceptedQuote == null) {
      if (
        disabledSteps.length === 0 &&
        currentQuoteData.Quote.WorkflowStep !== "SEND_FOR_SIGNING" &&
        currentQuoteData.Quote.WorkflowStep !== "CUSTOMER_ACCEPTED"
      ) {
        const previousWorkflowStep = currentQuoteData.Quote.PreviousWorkflowStep
          ? currentQuoteData.Quote.PreviousWorkflowStep
          : currentQuoteData.Quote.WorkflowStep;
        const workflowStepIndex =
          currentQuoteData.Enums.data.indexOf(previousWorkflowStep);
        const steps = currentQuoteData.Enums.data.filter(
          (step, index) => index > workflowStepIndex
        );
        setDisabledSteps(steps);
      }
    } else {
      if (!initSteps) {
        setDisabledSteps([]);
        setInitSteps(true);
      }
    }
  };

  const previewQuote = () => {
    window.open(`${previewUrl}?preview=1`, "_blank");
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger mr-3",
    },
    buttonsStyling: false,
  });

  return (
    <div id="sidebar-menu">
      {stepError && stepError.error === true && (
        <SweetAlert
          error
          title={<span style={{ fontSize: "24px" }}>Error</span>}
          onConfirm={(e) => setStepError({ error: false, message: "" })}
        >
          {stepError.message}
        </SweetAlert>
      )}
      <ul className="metismenu" id="side-menu">
        <li className="menu-title quote-builder-title rbd-pc-hero-blue">
          Quote Builder
          {currentQuoteData && currentQuoteData.Quote === null && (
            <span className="d-block text-capitalize font-weight-normal mt-1">
              No HubSpot Deal Loaded
            </span>
          )}
        </li>
        {currentQuoteData && currentQuoteData.Quote && (
          <>
            <li className="quote-builder-menus rbd-pc-hero-blue">
              <Link
                to={""}
                onClick={(e) =>
                  goToWorkflowStep(
                    e,
                    "QUOTE_SETUP",
                    `/deal/${currentQuoteData.Quote.HubSpotDealId}/${currentQuoteData.Quote.QuoteId}`
                  )
                }
                className={`${
                  currentQuoteData.Quote.WorkflowStep === "QUOTE_SETUP" &&
                  "active"
                } ${disabledSteps.indexOf("QUOTE_SETUP") >= 0 && "disabled"} ${
                  disableQuote && "disabled"
                } ${disableQuoteInitial && "disabled"}  waves-effect`}
              >
                <i className="fas fa-file-import"></i>{" "}
                <span> Quote Setup </span>
              </Link>
              <Link
                to={""}
                onClick={(e) =>
                  goToWorkflowStep(
                    e,
                    "QUOTE_ITEMS",
                    `/quote-items/${currentQuoteData.Quote.QuoteId}`
                  )
                }
                className={`${
                  currentQuoteData.Quote.WorkflowStep === "QUOTE_ITEMS" &&
                  "active"
                } ${disabledSteps.indexOf("QUOTE_ITEMS") >= 0 && "disabled"} ${
                  disableQuote && "disabled"
                } ${disableQuoteInitial && "disabled"} waves-effect`}
              >
                <i className="fas fa-clipboard-list"></i>{" "}
                <span> Quote Items </span>
              </Link>
              <Link
                to={""}
                onClick={(e) =>
                  goToWorkflowStep(
                    e,
                    "QUOTE_RATES",
                    `/quote-rates/${currentQuoteData.Quote.QuoteId}`
                  )
                }
                className={`${
                  currentQuoteData.Quote.WorkflowStep === "QUOTE_RATES" &&
                  "active"
                } ${disabledSteps.indexOf("QUOTE_RATES") >= 0 && "disabled"} ${
                  disableQuote && "disabled"
                } ${disableQuoteInitial && "disabled"}  waves-effect`}
              >
                <i className="fas fa-file-invoice-dollar"></i>{" "}
                <span> Usage Rates </span>
              </Link>
              <Link
                to={""}
                onClick={(e) =>
                  goToWorkflowStep(
                    e,
                    "CUSTOMISE_PROPOSAL",
                    `/customise-proposal/${currentQuoteData.Quote.QuoteId}`
                  )
                }
                className={`${
                  currentQuoteData.Quote.WorkflowStep ===
                    "CUSTOMISE_PROPOSAL" && "active"
                } ${
                  disabledSteps.indexOf("CUSTOMISE_PROPOSAL") >= 0 && "disabled"
                } ${disableQuote && "disabled"} ${
                  disableQuoteInitial && "disabled"
                }  waves-effect`}
              >
                <i className="fas fa-th-list"></i>
                <span> Customise Proposal </span>
              </Link>
              {previewUrl === "" && (
                <Link
                  to={""}
                  onClick={(e) =>
                    goToWorkflowStep(
                      e,
                      "SEND_PROPOSAL",
                      `/send-proposal/${currentQuoteData.Quote.QuoteId}`
                    )
                  }
                  className={`${
                    currentQuoteData.Quote.WorkflowStep === "SEND_PROPOSAL" &&
                    "active"
                  }  ${
                    disabledSteps.indexOf("SEND_PROPOSAL") >= 0 && "disabled"
                  } ${disableQuote && "disabled"} ${
                    disableQuoteInitial && "disabled"
                  }  waves-effect`}
                >
                  <i className="fas fa-file-signature"></i>
                  <span> Send Proposal</span>
                </Link>
              )}
              {previewUrl && (
                <div className="accepted-quote-link">
                  <Link
                    to={""}
                    onClick={(e) =>
                      goToWorkflowStep(
                        e,
                        "SEND_PROPOSAL",
                        `/send-proposal/${currentQuoteData.Quote.QuoteId}`
                      )
                    }
                    className={`${
                      currentQuoteData.Quote.WorkflowStep === "SEND_PROPOSAL" &&
                      "active"
                    } ${
                      disabledSteps.indexOf("SEND_PROPOSAL") >= 0 && "disabled"
                    } ${disableQuote && "disabled"} ${
                      disableQuoteInitial && "disabled"
                    }  waves-effect`}
                  >
                    <i className="fas fa-file-signature"></i>
                    <span> Send Proposal</span>
                  </Link>
                  {currentQuoteData.Quote.AcceptedQuote && (
                    <a
                      className="accepted-quote rbd-sc-teal"
                      onClick={previewQuote}
                    >
                      Accepted {currentQuoteData.Quote.AcceptedQuote}
                    </a>
                  )}
                </div>
              )}
            </li>

            <li className="menu-title quote-builder-title caf-builder-title rbd-sc-teal">
              CAF Builder
            </li>
            <li className="quote-builder-menus caf-builder-menus rbd-sc-teal">
              <Link
                to={""}
                onClick={(e) =>
                  goToWorkflowStep(
                    e,
                    "BILLING_CONTACT_CAF",
                    `/billing-contact-caf/${currentQuoteData.Quote.QuoteId}`
                  )
                }
                className={`${
                  currentQuoteData.Quote.WorkflowStep ===
                    "BILLING_CONTACT_CAF" && "active"
                } ${disableQuote && "disabled"}  waves-effect`}
              >
                <i className="fas fa-address-card"></i>{" "}
                <span> Billing Contact CAF </span>
              </Link>
              <Link
                to={""}
                onClick={(e) =>
                  goToWorkflowStep(
                    e,
                    "CUSTOMISE_CAF",
                    `/customise-caf/${currentQuoteData.Quote.QuoteId}`
                  )
                }
                className={`${
                  currentQuoteData.Quote.WorkflowStep === "CUSTOMISE_CAF" &&
                  "active"
                } ${disableQuote && "disabled"}  waves-effect`}
              >
                <i className="fas fa-newspaper"></i>{" "}
                <span> Customise CAF </span>
              </Link>
              <Link
                to={""}
                onClick={(e) =>
                  goToWorkflowStep(
                    e,
                    "PREFILL_CAF",
                    `/prefill-caf/${currentQuoteData.Quote.QuoteId}`
                  )
                }
                className={`${
                  currentQuoteData.Quote.WorkflowStep === "PREFILL_CAF" &&
                  "active"
                } ${disableQuote && "disabled"}  waves-effect`}
              >
                <i className="fas fa-edit"></i> <span> Prefill CAF </span>
              </Link>
              <Link
                to={""}
                onClick={(e) =>
                  goToWorkflowStep(
                    e,
                    "SEND_FOR_SIGNING",
                    `/send-for-signing/${currentQuoteData.Quote.QuoteId}`
                  )
                }
                className={`${
                  currentQuoteData.Quote.WorkflowStep === "SEND_FOR_SIGNING" &&
                  "active"
                } ${
                  currentQuoteData.Quote.WorkflowStep !== "SEND_FOR_SIGNING"
                    ? "disabled"
                    : ""
                } ${disableQuote && "disabled"}  waves-effect`}
              >
                <i className="fas fa-paper-plane"></i>{" "}
                <span> Send for Signing </span>
              </Link>
              <Link
                to={""}
                onClick={(e) =>
                  goToWorkflowStep(
                    e,
                    "CUSTOMER_ACCEPTED",
                    `/customer-accepted/${currentQuoteData.Quote.QuoteId}`
                  )
                }
                className={`${
                  currentQuoteData.Quote.WorkflowStep === "CUSTOMER_ACCEPTED" &&
                  "active"
                } ${
                  currentQuoteData.Quote.WorkflowStep !== "CUSTOMER_ACCEPTED"
                    ? "disabled"
                    : ""
                } waves-effect`}
              >
                <i className="fas fa-paper-plane"></i>{" "}
                <span> Customer Accepted </span>
              </Link>
            </li>
          </>
        )}

        <li className="menu-title">Quotes</li>
        <li className="main-menus">
          <Link to="/my-quotes" className="waves-effect">
            <i className="ion ion-md-person"></i>
            <span> My Quotes </span>
          </Link>
          <Link to="/quotes" className="waves-effect">
            <i className="ion ion-ios-people"></i>
            <span> All Quotes </span>
          </Link>
          <Link
            to="/new-deal"
            className="waves-effect"
            onClick={(e) => {
              e.preventDefault();
              createNewQuote({
                variables: {
                  CreateNewQuoteInput: {},
                },
              })
                .then(
                  ({
                    data: {
                      createNewQuote: { status, message, quote },
                    },
                  }) => {
                    Swal.fire({
                      icon: status === 201 ? "success" : "error",
                      text: message,
                      timer: 2000,
                      showConfirmButton: false,
                    });
                    if (quote) {
                      setTimeout(() => {
                        window.location.href =
                          "/deal/" + quote.HubSpotDealId + "/" + quote.QuoteId;
                      }, 2000);
                    }
                  }
                )
                .catch((err) => {
                  Swal.fire({
                    icon: "error",
                    text: "Something went wrong!",
                    timer: 2000,
                  });
                  console.log(err);
                });
            }}
          >
            <i className="ion ion ion-md-add-circle-outline"></i>
            <span> New Quote </span>
          </Link>
        </li>

        {/* {Boolean(user && user.admin) && ( */}
        {/* {isAuthenticated() && ( */}
        {Boolean(isAuthenticated() && isAdmin()) && (
          <li className="menu-title">Administration</li>
        )}
        {Boolean(isAuthenticated() && isAdmin()) && (
          // {isAuthenticated() && (
          <li className="main-menus">
            <Link to={adminURL("/admin/products")} className="waves-effect">
              <i className="ion ion-md-barcode"></i>
              <span> Products </span>
            </Link>
            <Link to={adminURL("/admin/categories")} className="waves-effect">
              <i className="ion ion-ios-list"></i>
              <span> Categories </span>
            </Link>
            <Link to={adminURL("/admin/bundles")} className="waves-effect">
              <i className="fas fa-layer-group"></i>
              <span> Bundles </span>
            </Link>
            <Link
              to={adminURL("/admin/templates/system")}
              onClick={(e) => {
                e.preventDefault();
                window.location.href = adminURL("/admin/templates/system");
              }}
              className="waves-effect"
            >
              <i className="ion ion-ios-paper"></i>
              <span>Edit System Templates</span>
            </Link>
            <Link
              to={adminURL("/admin/templates/custom")}
              onClick={(e) => {
                e.preventDefault();
                window.location.href = adminURL("/admin/templates/custom");
              }}
              className="waves-effect"
            >
              <i className="ion ion-ios-paper"></i>
              <span>Edit Custom Templates</span>
            </Link>
            <Link
              to={adminURL("/admin/zapier-integration")}
              className="waves-effect"
            >
              <i className="fas fa-bolt"></i>
              <span>Zapier Integration</span>
            </Link>
            <Link to={adminURL("/admin/admin-config")} className="waves-effect">
              <i className="ion ion-ios-settings"></i>
              <span> Configuration </span>
            </Link>
            <Link
              to={adminURL("/admin/caf-form-builder")}
              className="waves-effect"
            >
              <i className="fab fa-wpforms"></i>
              <span> CAF Form Sections</span>
            </Link>
            {/* <Link to={adminURL("/admin/manage-users")} className="waves-effect">
              <i className="fas fa-users"></i>
              <span> Manage Users</span>
            </Link> */}
            <Link
              to={adminURL("/admin/manage-agents")}
              className="waves-effect"
            >
              <i className="fas fa-users"></i>
              <span> Manage Agents</span>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { is_toggle: false };
  }

  componentDidMount() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("mm-active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active"); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div className="left side-menu">
          {this.props.is_toggle ? (
            <PerfectScrollbar>
              <SideNav user={this.props.user} />
            </PerfectScrollbar>
          ) : (
            <SideNav />
          )}
          <div className="clearfix"></div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { is_toggle } = state.Layout;
  const { user } = state.User;
  return { is_toggle, user };
};

export default withRouter(connect(mapStatetoProps, { toggleSidebar })(Sidebar));
