import React, { Component, useEffect } from "react";
import Layout from "./components/Layout/";
import {
  withRouter,
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
  useHistory,
} from "react-router-dom";
import { connect } from "react-redux";

import routes from "./routes";
import "./custom.css";
import "./App.scss";
import "./css/custom.css";
// okta
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import {
  LoginCallback,
  Security,
  SecureRoute,
  useOktaAuth,
} from "@okta/okta-react";
import { oktaConfig } from "./lib/oktaConfig";

//Fake backend
import fakeBackend from "./helpers/fakeBackend";

// Get all Auth methods
import { isUserAuthenticated } from "./helpers/authUtils";
import BaseRoutes from "./components/BaseRoutes";

// Activating fake backend
fakeBackend();

const oktaAuth = new OktaAuth(oktaConfig);

// console.log(process.env.REACT_APP_OKTA_DOMAIN);
// console.log(process.env.REACT_APP_OKTA_CLIENT);
// console.log(window.location.origin + "/login/callback");
// const oktaAuth = new OktaAuth({
//   issuer: `${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/default`,
//   clientId: process.env.REACT_APP_OKTA_CLIENT,
//   redirectUri: window.location.origin + "/login/callback",
// });

function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <Layout>
          <WrappedComponent
            pathArg={this.props.match.params.pathArg}
          ></WrappedComponent>
        </Layout>
      );
    }
  };
}
//rewrite class app component to functional component with hooks
const App = (props) => {
  // const [isHidden, setIsHidden] = React.useState(true);
  // React.useEffect(() => {
  //   isUserAuthenticated().then((data) => {
  //     let currentURLLastSegment = window.location.href.split("/").pop();
  //     if (data.status === "error") {
  //       currentURLLastSegment !== "login"
  //         ? (window.location = "/login")
  //         : setIsHidden(false);
  //     } else {
  //       localStorage.setItem("hubspot_user", data.hubspot_user);
  //       currentURLLastSegment === "login" || currentURLLastSegment === ""
  //         ? (window.location = "/dashboard")
  //         : setIsHidden(false);
  //     }
  //   });
  // }, []);

  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    // history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
    window.location = "/dashboard";
    // props.history.replace(
    //   toRelativeUrl(originalUri || "/", window.location.origin)
    // );
    // props.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    // generate code for redirecting to dashboard from oktaAuth login
    // props.history.replace(
    //   toRelativeUrl(originalUri || "/dashboard", window.location.origin)
    // );
  };

  // const PrivateRoute = ({ component: Component, ...rest }) => {
  //   return (
  //     <Route
  //       {...rest}
  //       render={(props) =>
  //         true ? <Component {...props} /> : <Redirect to="/logout" />
  //       }
  //     />
  //   );
  // };

  return (
    <React.Fragment>
      <Router>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Switch>
            <Route path="/login/callback" component={LoginCallback} />
            <BaseRoutes />
            <Route path="*" component={NotFound} />
          </Switch>
        </Security>
      </Router>
    </React.Fragment>
  );
};
// class App extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isHidden: true,
//     };
//   }

//   componentDidMount() {
//     isUserAuthenticated().then((data) => {
//       let currentURLLastSegment = window.location.href.split("/").pop();
//       console.log(data.message);
//       if (data.status === "error") {
//         currentURLLastSegment !== "login"
//           ? (window.location = "/login")
//           : this.setState({ isHidden: false });
//       } else {
//         localStorage.setItem("hubspot_user", data.hubspot_user);
//         currentURLLastSegment === "login" || currentURLLastSegment === ""
//           ? (window.location = "/dashboard")
//           : this.setState({ isHidden: false });
//       }
//     });
//   }

//   render() {
//     const PrivateRoute = ({ component: Component, ...rest }) => {
//       // if(rest.forAdmin) {
//       //   if(this.props.user && this.props.user.isAdmin) {
//       //     return <Route {...rest} render={(props) => (
//       //       true
//       //         ? <Component {...props} />
//       //         : <Redirect to='/logout' />
//       //     )} />
//       //   } else {
//       //     return <Redirect exact to='/dashboard' />
//       //   }
//       // } else {
//       //   return <Route {...rest} render={(props) => (
//       //     true
//       //       ? <Component {...props} />
//       //       : <Redirect to='/logout' />
//       //   )} />
//       // }
//       return (
//         <Route
//           {...rest}
//           render={(props) =>
//             true ? <Component {...props} /> : <Redirect to="/logout" />
//           }
//         />
//       );
//     };

//     return this.state.isHidden ? (
//       <div></div>
//     ) : (
//       <React.Fragment>
//         <Router>
//           <Switch>
//             {routes.map((route, idx) =>
//               route.ispublic ? (
//                 <Route
//                   exact
//                   path={route.path}
//                   component={withLayout(route.component)}
//                   key={idx}
//                 />
//               ) : (
//                 <PrivateRoute
//                   exact
//                   path={route.path}
//                   component={withLayout(route.component)}
//                   key={idx}
//                   forAdmin={route.forAdmin}
//                 />
//               )
//             )}
//             <Route path="*" component={NotFound} />
//           </Switch>
//         </Router>
//       </React.Fragment>
//     );
//   }
// }

const NotFound = () => {
  return <div>Not found</div>;
};

const mapStatetoProps = (state) => {
  const { user } = state.User;
  return { user };
};

export default withRouter(connect(mapStatetoProps)(App));
