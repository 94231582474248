import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";

import { activateAuthLayout } from "../../../store/actions";
import { getQuoteBuilderURL, quoteStatusBadge } from "../../../helpers/common";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Swal from "sweetalert2";

const QUOTES = gql`
  query getQuotes {
    Quotes {
      QuoteId
      CompanyName
      Description
      Status
      WorkflowStep
      HubSpotDealId
      QuoteType1 {
        Name
      }
      QuoteType2 {
        Name
      }
      Created
      ModifiedTimestamp
    }
  }
`;

const UPDATE_QUOTE = gql`
  mutation updateQuote($QuoteInput: QuoteInput) {
    updateQuote(input: $QuoteInput) {
      QuoteId
      HubSpotDealId
      QuoteTypeId1
      QuoteTypeId2
      Description
      WorkflowStep
    }
  }
`;

const REVERT_QUOTE = gql`
  mutation revertQuote($QuoteInput: QuoteInput) {
    revertQuote(input: $QuoteInput) {
      QuoteId
      Status
      AcceptedQuote
    }
  }
`;

const DUPLICATE_QUOTE = gql`
  mutation duplicateQuote($QuoteInput: QuoteInput) {
    duplicateQuote(input: $QuoteInput) {
      QuoteId
      HubSpotDealId
      QuoteTypeId1
      QuoteTypeId2
      Description
      WorkflowStep
    }
  }
`;

const ActionDropdown = (props) => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const quoteURL = getQuoteBuilderURL(
    props.quote.WorkflowStep,
    props.quote.QuoteId,
    props.quote.HubSpotDealId
  );
  const [updateQuote, { data: updatedQuoteData }] = useMutation(UPDATE_QUOTE);
  const [revertQuote, { data: revertedQuoteData }] = useMutation(REVERT_QUOTE);
  const [duplicateQuote, { data: duplicatedQuoteData }] =
    useMutation(DUPLICATE_QUOTE);

  const updateQuoteStatus = (quoteId, status) => {
    updateQuote({
      variables: {
        QuoteInput: {
          QuoteId: quoteId,
          Status: status,
        },
      },
    });
  };

  const revertQuoteStatus = (quoteId, status) => {
    revertQuote({
      variables: {
        QuoteInput: {
          QuoteId: quoteId,
          Status: status,
          AcceptedQuote: null,
        },
      },
    });
  };
  if (updatedQuoteData || duplicatedQuoteData) {
    props.refetch();
  }
  if (revertedQuoteData) {
    props.refetch();
  }

  return (
    <ButtonDropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      onClick={(e) => e.stopPropagation()}
    >
      <DropdownToggle size="sm" className="list-action-menu">
        <i className="fas fa-ellipsis-h"></i>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem>
          <a href={quoteURL} target="_blank" rel="noopener noreferrer">
            View Quote
          </a>
        </DropdownItem>
        <DropdownItem
          disabled={props.quote.Status === "accepted"}
          onClick={(e) => updateQuoteStatus(props.quote.QuoteId, "voided")}
        >
          Void Quote
        </DropdownItem>
        <DropdownItem
          disabled={
            props.quote.Status !== "sent" && props.quote.Status !== "viewed"
          }
          onClick={(e) => updateQuoteStatus(props.quote.QuoteId, "accepted")}
        >
          Quote Accepted
        </DropdownItem>
        <DropdownItem
          disabled={
            props.quote.Status !== "sent" && props.quote.Status !== "viewed"
          }
          onClick={(e) => updateQuoteStatus(props.quote.QuoteId, "declined")}
        >
          Quote Declined
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            duplicateQuote({
              variables: {
                QuoteInput: {
                  QuoteId: props.quote.QuoteId,
                },
              },
            })
              .then(({ data: { duplicateQuote } }) => {
                if (duplicateQuote) {
                  Swal.fire({
                    icon: "success",
                    title: "Quote Duplicated",
                    showConfirmButton: false,
                    text: `Opening Quote # ${duplicateQuote.QuoteId} now...`,
                  });

                  setTimeout(() => {
                    window.location.href =
                      "/deal/" +
                      duplicateQuote.HubSpotDealId +
                      "/" +
                      duplicateQuote.QuoteId;
                  }, 2000);
                }
              })
              .catch((err) => {
                Swal.fire({
                  icon: "error",
                  title: "Something went wrong",
                });
              });
          }}
        >
          Duplicate
        </DropdownItem>
        {![
          "in_progress",
          "sent",
          "viewed",
          "accepted",
          "declined",
          "voided",
        ].includes(props.quote.Status) && (
          <DropdownItem
            onClick={(e) => {
              Swal.fire({
                title: "Are you sure?",
                text: "You are about to revert the quote option status!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, revert it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  revertQuoteStatus(props.quote.QuoteId, "in_progress");
                  Swal.fire(
                    "Reverted!",
                    "The quote status has been reverted.",
                    "success"
                  );
                }
              });
            }}
          >
            Revert back to In Progress
          </DropdownItem>
        )}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

const QuotesAll = (props) => {
  props.activateAuthLayout();

  // initialize states
  const [quotesData, setQuotesData] = useState(null);
  const [isInit, setIsInit] = useState(false);

  // initialize apollo graphql queries and mutations
  const { data, refetch } = useQuery(QUOTES);

  const statusFilter = useRef();
  const quoteType1Filter = useRef();
  const quoteType2Filter = useRef();

  const statusOptions = [
    {
      value: "in_progress",
      label: "In Progress",
    },
    {
      value: "sent",
      label: "Sent",
    },
    {
      value: "viewed",
      label: "Viewed",
    },
    {
      value: "initial_acceptance",
      label: "Initial Acceptance",
    },
    {
      value: "accepted",
      label: "Accepted",
    },
    {
      value: "declined",
      label: "Declined",
    },
    {
      value: "voided",
      label: "Voided",
    },
  ];

  const quoteTypes = [
    {
      value: "CAPEX",
      label: "CAPEX",
    },
    {
      value: "OPEX",
      label: "OPEX",
    },
    {
      value: "QANTAS",
      label: "QANTAS",
    },
    {
      value: "ENERDS",
      label: "ENERDS",
    },
  ];

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 2,
    }),
  };

  const handleStatusFilterChange = (e) => {
    const { value = "" } = e;
    statusFilter.current = value;
    filterData();
  };

  const handleQuoteTypeChange = (e, type) => {
    const { value = "" } = e;
    if (type == "QuoteType1") {
      quoteType1Filter.current = value;
    } else {
      quoteType2Filter.current = value;
    }
    filterData();
  };

  const filterData = () => {
    let tempData = data.Quotes;
    if (statusFilter.current) {
      tempData = tempData.filter((item) => {
        return item.Status == statusFilter.current;
      });
    }

    if (quoteType1Filter.current) {
      tempData = tempData.filter((item) => {
        return item.QuoteType1.Name == quoteType1Filter.current;
      });
    }

    if (quoteType2Filter.current) {
      tempData = tempData.filter((item) => {
        return item.QuoteType2.Name == quoteType2Filter.current;
      });
    }

    const quotes = tempData.map((quote, index) => {
      const quoteURL = getQuoteBuilderURL(
        quote.WorkflowStep,
        quote.QuoteId,
        quote.HubSpotDealId
      );
      return {
        QuoteId: quote.QuoteId,
        CompanyName: quote.CompanyName,
        Description: quote.Description,
        Status: quoteStatusBadge(quote.Status),
        QuoteType1: quote.QuoteType1.Name,
        QuoteType2: quote.QuoteType2.Name,
        LastUpdated: quote.ModifiedTimestamp,
        Action: <ActionDropdown quote={quote} refetch={refetch} />,
        clickEvent: () => window.open(quoteURL),
        Created: quote.Created,
      };
    });

    quotes.sort(function (a, b) {
      var c = new Date(a.Created);
      var d = new Date(b.Created);
      if (c > d) return -1;
      if (c < d) return 1;
    });

    setQuotesData({
      columns: [
        {
          label: [
            "Quote ID",
            <i key={`product-id`} className="fa fa-sort float-right"></i>,
          ],
          field: "QuoteId",
          sort: "asc",
        },
        {
          label: [
            "Company Name",
            <i key={`product-id`} className="fa fa-sort float-right"></i>,
          ],
          field: "CompanyName",
          sort: "asc",
        },
        {
          label: [
            "Description",
            <i key={`product-id`} className="fa fa-sort float-right"></i>,
          ],
          field: "Description",
          sort: "asc",
        },
        {
          label: [
            "Status",
            <i key={`product-id`} className="fa fa-sort float-right"></i>,
          ],
          field: "Status",
          sort: "asc",
        },
        {
          label: [
            "Quote Type 1",
            <i key={`product-id`} className="fa fa-sort float-right"></i>,
          ],
          field: "QuoteType1",
          sort: "asc",
        },
        {
          label: [
            "Quote Type 2",
            <i key={`product-id`} className="fa fa-sort float-right"></i>,
          ],
          field: "QuoteType2",
          sort: "asc",
        },
        {
          label: [
            "Last Updated",
            <i key={`product-id`} className="fa fa-sort float-right"></i>,
          ],
          field: "LastUpdated",
          sort: "asc",
        },
        {
          label: "Action",
          field: "Action",
          sort: "disabled",
        },
      ],
      rows: quotes,
    });
  };
  // component mounted/willmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (data && data.Quotes) {
      const quotes = data.Quotes.map((quote, index) => {
        const quoteURL = getQuoteBuilderURL(
          quote.WorkflowStep,
          quote.QuoteId,
          quote.HubSpotDealId
        );
        return {
          QuoteId: quote.QuoteId,
          CompanyName: quote.CompanyName,
          Description: quote.Description,
          Status: quoteStatusBadge(quote.Status),
          QuoteType1: quote.QuoteType1.Name,
          QuoteType2: quote.QuoteType2.Name,
          LastUpdated: quote.ModifiedTimestamp,
          Action: <ActionDropdown quote={quote} refetch={refetch} />,
          clickEvent: () => window.open(quoteURL),
          Created: quote.Created,
        };
      });

      quotes.sort(function (a, b) {
        var c = new Date(a.Created);
        var d = new Date(b.Created);
        if (c > d) return -1;
        if (c < d) return 1;
      });

      setQuotesData({
        columns: [
          {
            label: [
              "Quote ID",
              <i key={`product-id`} className="fa fa-sort float-right"></i>,
            ],
            field: "QuoteId",
            sort: "asc",
          },
          {
            label: [
              "Company Name",
              <i key={`product-id`} className="fa fa-sort float-right"></i>,
            ],
            field: "CompanyName",
            sort: "asc",
          },
          {
            label: [
              "Description",
              <i key={`product-id`} className="fa fa-sort float-right"></i>,
            ],
            field: "Description",
            sort: "asc",
          },
          {
            label: [
              "Status",
              <i key={`product-id`} className="fa fa-sort float-right"></i>,
            ],
            field: "Status",
            sort: "asc",
          },
          {
            label: [
              "Quote Type 1",
              <i key={`product-id`} className="fa fa-sort float-right"></i>,
            ],
            field: "QuoteType1",
            sort: "asc",
          },
          {
            label: [
              "Quote Type 2",
              <i key={`product-id`} className="fa fa-sort float-right"></i>,
            ],
            field: "QuoteType2",
            sort: "asc",
          },
          {
            label: [
              "Last Updated",
              <i key={`product-id`} className="fa fa-sort float-right"></i>,
            ],
            field: "LastUpdated",
            sort: "asc",
          },
          {
            label: "Action",
            field: "Action",
            sort: "disabled",
          },
        ],
        rows: quotes,
      });
    }
  }, [data]);

  return (
    <React.Fragment>
      {
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col sm="6">
                  <h4 className="page-title">All Quotes</h4>
                </Col>
              </Row>
            </div>

            <Row>
              <Col lg="12">
                <Card>
                  <Row className="pl-3">
                    <Col>
                      <h6 className="page-title">Filter Quotes</h6>
                    </Col>
                  </Row>
                  <Row className="pl-3">
                    <Col lg="4">
                      <Select
                        options={statusOptions}
                        placeholder={"Status"}
                        styles={customStyles}
                        onChange={handleStatusFilterChange}
                      />
                    </Col>
                  </Row>
                  <Row className="pl-3 pt-2">
                    <Col lg="2">
                      <Select
                        options={quoteTypes}
                        styles={customStyles}
                        placeholder={"Quote Type 1"}
                        onChange={(e) => handleQuoteTypeChange(e, "QuoteType1")}
                      />
                    </Col>
                    <Col lg="2">
                      <Select
                        options={quoteTypes}
                        styles={customStyles}
                        placeholder={"Quote Type 2"}
                        onChange={(e) => handleQuoteTypeChange(e, "QuoteType2")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CardBody className="text-center">
                        {data && quotesData && (
                          <MDBDataTable
                            className={"quotes-list-table"}
                            style={{ overFlow: "visible" }}
                            sortable
                            striped
                            borderless
                            theadColor="table-dark"
                            hover
                            responsiveSm
                            entriesLabel={"Show quotes"}
                            noBottomColumns
                            noRecordsFoundLabel={"No quotes found"}
                            // order={['Created', 'desc']}
                            data={quotesData}
                          />
                        )}
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default connect(null, { activateAuthLayout })(QuotesAll);
