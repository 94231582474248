import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import { activateAuthLayout } from "../../../store/actions";
import { connect } from "react-redux";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Select, { Creatable } from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getQuoteBuilderURL } from "../../../helpers/common";
import ColumnHeadersTooltip from "../../../components/ColumnHeadersTooltip";
import { titleCase } from "../../../helpers/textTransform";

/**
 * graphQL query for all the data needed for quote items UI
 * Quote - quote based from the passed quote id in the URL
 * Bundles, ProductCategories - list all the product for the live search
 * QuoteItems - query all the quote items of the current quote based on the URL
 **/
const DATA = gql`
  query Data($QuoteId: ID) {
    Quote(QuoteId: $QuoteId) {
      QuoteId
      HubSpotDealId
      Description
      HubSpotDealValue
      HubSpotDealOwner
      PrimaryContact
      QuoteTypeId1
      QuoteType1 {
        QuoteTypeId
        Name
      }
      QuoteTypeId2
      QuoteType2 {
        QuoteTypeId
        Name
      }
      WorkflowStep
      AcceptedQuote
      ModifiedTimestamp
      Status
    }

    QuoteRates(QuoteId: $QuoteId) {
      QuoteRateId
      QuoteId
      RateType
      RateUnits
      Quote1Rate
      Quote2Rate
      CompareDesc
      ComparePrice
      CompareQty
      Order
    }
  }
`;

const CREATE_QUOTE_RATES = gql`
  mutation createQuoteRates($QuoteRatesList: [QuoteRatesList]) {
    createQuoteRates(input: $QuoteRatesList) {
      QuoteRateId
      QuoteId
      RateType
      RateUnits
      Quote1Rate
      Quote2Rate
      CompareDesc
      ComparePrice
      CompareQty
      Order
    }
  }
`;

const UPDATE_QUOTE_RATE = gql`
  mutation updateQuoteRate($QuoteRateInput: QuoteRateInput) {
    updateQuoteRate(input: $QuoteRateInput) {
      QuoteRateId
      QuoteId
      RateType
      RateUnits
      Quote1Rate
      Quote2Rate
      CompareDesc
      ComparePrice
      CompareQty
      Order
    }
  }
`;

const UPDATE_QUOTE_RATES = gql`
  mutation updateQuoteRates($QuoteRatesList: [QuoteRatesList]) {
    updateQuoteRates(input: $QuoteRatesList) {
      QuoteRateId
      QuoteId
      RateType
      RateUnits
      Quote1Rate
      Quote2Rate
      CompareDesc
      ComparePrice
      CompareQty
      Order
    }
  }
`;

const DELETE_QUOTE_RATE = gql`
  mutation deleteQuoteRate($QuoteRateId: ID!) {
    deleteQuoteRate(QuoteRateId: $QuoteRateId) {
      QuoteRateId
      QuoteId
      RateType
      RateUnits
      Quote1Rate
      Quote2Rate
      CompareDesc
      ComparePrice
      CompareQty
      Order
    }
  }
`;

const UPDATE_QUOTE = gql`
  mutation updateQuote($QuoteInput: QuoteInput) {
    updateQuote(input: $QuoteInput) {
      QuoteId
      HubSpotDealId
      WorkflowStep
      AcceptedQuote
      ModifiedTimestamp
      Token
      QuoteVersions {
        VersionId
        File
        Timestamp
      }
    }
  }
`;

const GENERATE_STATIC_PROPOSAL = gql`
  mutation generateStaticProposalHTML($QuoteId: Int, $Token: String) {
    generateStaticProposalHTML(QuoteId: $QuoteId, Token: $Token) {
      message
      QuoteVersion {
        QuoteId
        VersionId
        Timestamp
        Token
        File
      }
    }
  }
`;

const QUOTE_RATES_COLUMN_HEADERS = [
  {
    id: 1,
    field: "RateType",
    label: "Rate Type",
    description: "The type of usage rate for this line item",
  },
  {
    id: 2,
    field: "Quote1Rate",
    label: "(Quote 1) Unit Price",
    description:
      "The price for this line item, of this rate type, for the first quote type to be included in the proposal",
    quoteType: true,
  },
  {
    id: 3,
    field: "Quote2Rate",
    label: "(Quote 2) Unit Price",
    description:
      "The price for this line item, of this rate type, for the second quote type to be included in the proposal",
    quoteType: true,
  },
  {
    id: 4,
    field: "RateUnits",
    label: "Rate Unit",
    description:
      'The increment or unit type for this rate.  For example, calls may be "Flat" or "per minute"',
    width: "180px",
  },
  {
    id: 5,
    field: "CompareDesc",
    label: "Comparison Description ",
    description:
      "When comparing to a competitor's quote or bill, enter the equivalent line item to which this rate should be compared (eg. Telstra STD calls)",
  },
  {
    id: 6,
    field: "ComparePrice",
    label: "Comparison Price",
    description:
      "The competitor's prices for the comparison rate, to which this line should be compared",
  },
  {
    id: 7,
    field: "CompareQty",
    label: "Comparison Qty",
    description:
      "The quantity from the competitor's bill or proposal for comparison purposes.  For example, if the line item is for mobile calls / minutes, and the customer made 100 minutes of mobile calls, enter 100 here",
  },
];

const QuoteBuilderRates = (props) => {
  props.activateAuthLayout();

  // initialize states
  const [isInit, setIsInit] = useState(false);
  const [stepError, setStepError] = useState(null);
  const [quoteRates, setQuoteRates] = useState(null);
  const [proceed, setProceed] = useState(false);
  const [previous, setPrevious] = useState(false);
  const [isCreatedQuoteRates, setIsCreatedQuoteRates] = useState(false);
  const [isUpdatedQuoteRates, setIsUpdatedQuoteRates] = useState(false);
  const [isDeletedQuoteRate, setIsDeletedQuoteRate] = useState(false);
  const [newQuoteRate, setNewQuoteRate] = useState({});
  const [disableQuote, setDisableQuote] = useState(false);

  const [RATE_TYPES, SET_RATE_TYPES] = useState([
    { label: "Local Calls", value: "LOCAL_CALLS" },
    { label: "National Calls", value: "NATIONAL_CALLS" },
    { label: "Mobile Calls", value: "MOBILE_CALLS" },
    { label: "1300 Calls", value: "_1300_CALLS" },
    { label: "1800 Calls", value: "_1800_CALLS" },
    { label: "Mobile Data Overage", value: "MOBILE_DATA_OVERAGE" },
    { label: "Data Overage", value: "DATA_OVERAGE" },
  ]);

  const [rateTypeValue, setRateTypeValue] = useState();

  const [RATE_UNITS, SET_RATE_UNITS] = useState([
    { label: "Unlimited", value: "UNLIMITED" },
    { label: "Flat", value: "FLAT" },
    { label: "Per Minute", value: "PER_MINUTE" },
    { label: "Per GB", value: "PER_GB" },
  ]);

  const [rateUnitValue, setRateUnitValue] = useState();

  const createOption = (v) => ({
    label: titleCase(v),
    value: v
      .replace(" ", "_")
      .toUpperCase(),
  });

  // initialize apollo graphql queries and mutations
  const { data, loading } = useQuery(DATA, {
    variables: { QuoteId: props.pathArg },
  });
  const [createQuoteRates, { data: createdQuoteRates }] =
    useMutation(CREATE_QUOTE_RATES);
  const [updateQuoteRate] = useMutation(UPDATE_QUOTE_RATE);
  const [updateQuoteRates, { data: updatedQuoteRates }] =
    useMutation(UPDATE_QUOTE_RATES);
  const [deleteQuoteRate, { data: deletedQuoteRate }] =
    useMutation(DELETE_QUOTE_RATE);
  const [updateQuote, { data: updatedQuoteData }] = useMutation(UPDATE_QUOTE);
  const [
    generateStaticProposalHTML,
    { data: generatedStaticProposalHTMLData },
  ] = useMutation(GENERATE_STATIC_PROPOSAL);

  /**
   * useEffect() - function equivalent to componentDidMount
   **/
  useEffect(() => {
    // populate quoteRates state on load of page
    if (data && data.Quote && data.QuoteRates && isInit === false) {
      // disable screen when status is accepted, declined or voided
      const status = data.Quote.Status;
      setDisableQuote(
        status === "accepted" || status === "declined" ? true : false
      );

      // redirect to the current workflow step
      if (
        data.Quote &&
        data.Quote.AcceptedQuote &&
        data.Quote.WorkflowStep == "CUSTOMER_ACCEPTED"
      ) {
        data.Quote &&
          data.Quote.WorkflowStep !== "QUOTE_RATES" &&
          window.location.replace(
            getQuoteBuilderURL(
              data.Quote.WorkflowStep,
              data.Quote.QuoteId,
              data.Quote.HubSpotDealId
            )
          );
      }

      // if (data.Quote && data.Quote.AcceptedQuote) {
      //   if (
      //     [
      //       'QUOTE_SETUP',
      //       'QUOTE_ITEMS',
      //       'QUOTE_RATES',
      //       'CUSTOMISE_PROPOSAL',
      //       'SEND_PROPOSAL',
      //     ].includes(data.Quote.WorkflowStep)
      //   ) {
      //     const quote = {};
      //     quote['QuoteId'] = data.Quote.QuoteId;
      //     quote['WorkflowStep'] = 'CUSTOMISE_CAF';
      //     updateQuote({
      //       variables: {
      //         QuoteInput: quote,
      //       },
      //     });
      //     window.location.replace(
      //       getQuoteBuilderURL(
      //         'CUSTOMISE_CAF',
      //         data.Quote.QuoteId,
      //         data.Quote.HubSpotDealId
      //       )
      //     );
      //     return;
      //   } else {
      //     window.location.replace(
      //       getQuoteBuilderURL(
      //         data.Quote.WorkflowStep,
      //         data.Quote.QuoteId,
      //         data.Quote.HubSpotDealId
      //       )
      //     );
      //     return;
      //   }
      // }
      setQuoteRates(data.QuoteRates);
      setIsInit(true);
    }

    if (data && createdQuoteRates && isCreatedQuoteRates === false) {
      const newArr = quoteRates;
      if (createdQuoteRates.createQuoteRates.length > 0) {
        newArr.push(createdQuoteRates.createQuoteRates[0]);
        setQuoteRates(newArr);
      } else {
        setStepError({
          error: true,
          message: "Rate selected already exists.",
        });
      }
      setIsCreatedQuoteRates(true);
    }

    if (data && updatedQuoteRates && isUpdatedQuoteRates === false) {
      setQuoteRates(updatedQuoteRates.updateQuoteRates);
      setIsUpdatedQuoteRates(true);
    }

    // update quoteRates state on delete of quote rate
    if (data && deletedQuoteRate && isDeletedQuoteRate === false) {
      const index = quoteRates
        .map((rate) => rate.QuoteRateId)
        .indexOf(deletedQuoteRate.deleteQuoteRate.QuoteRateId);
      const newArr = quoteRates;
      newArr.splice(index, 1);
      setQuoteRates(newArr);
      setIsDeletedQuoteRate(true);
    }

    if (previous === true && updatedQuoteData) {
      window.location.replace(`/quote-items/${data.Quote.QuoteId}`);
      setPrevious(false);
    }

    if (proceed === true && generatedStaticProposalHTMLData) {
      window.location.replace(`/send-proposal/${data.Quote.QuoteId}`);
    }
  }, [
    isInit,
    data,
    createdQuoteRates,
    isCreatedQuoteRates,
    updatedQuoteRates,
    isUpdatedQuoteRates,
    deletedQuoteRate,
    isDeletedQuoteRate,
    quoteRates,
    previous,
    updatedQuoteData,
    proceed,
    generatedStaticProposalHTMLData,
  ]);

  const onNewQuoteRateChange = (e, field) => {
    const qr = { ...newQuoteRate };
    qr[field] = e.target ? e.target.value : e.value;
    setNewQuoteRate(qr);
  };

  const addNewQuoteRate = () => {
    if (
      newQuoteRate.RateType !== "" &&
      quoteRates.filter((rate) => rate.RateType === newQuoteRate.RateType)
        .length > 0
    ) {
      setStepError({
        error: true,
        message: "Rate selected already exists.",
      });
    } else if (
      Object.keys(newQuoteRate).length > 0 &&
      newQuoteRate.RateType !== ""
    ) {
      setIsCreatedQuoteRates(false);
      const quoteRatesCopy = Array.from(quoteRates);
      quoteRatesCopy.sort((a, b) => (a.Order > b.Order ? 1 : -1));
      newQuoteRate.Order =
        quoteRatesCopy.length > 0 ? quoteRatesCopy.pop().Order + 1 : 1;
      newQuoteRate.QuoteId = props.pathArg;
      createQuoteRates({
        variables: {
          // QuoteRatesList: [newQuoteRate],
          QuoteRatesList: [{
            ...newQuoteRate,
            QuoteId: parseInt(newQuoteRate.QuoteId),
            Quote1Rate: parseFloat(newQuoteRate.Quote1Rate),
            Quote2Rate: parseFloat(newQuoteRate.Quote2Rate),
            ComparePrice: parseFloat(newQuoteRate.ComparePrice),
            CompareQty: parseInt(newQuoteRate.CompareQty),
          }],
        },
      });
    } else {
      setStepError({
        error: true,
        message:
          "Rate Type is required. Please set the rate type to add new rate.",
      });
    }
  };

  /**
   * Update the quoteRate state when user update the fields of the quote rates
   */
  const updateQuoteRateEntry = (e, field, quoteRateId) => {
    const index = quoteRates
      .map((rate) => rate.QuoteRateId)
      .indexOf(quoteRateId);
    const newItem = Object.assign({}, quoteRates[index]);
    newItem[field] = e.target ? e.target.value : e.value; // check if field value is from input or dropdown
    const newArr = [...quoteRates];
    newArr[index] = newItem;
    updateQuoteRate({
      variables: {
        QuoteRateInput: newItem,
      },
    });
    setQuoteRates(newArr);
  };

  /**
   * delete the quote rate entry, also in the DB
   */
  const deleteQuoteRateEntry = (e, quoteRateId) => {
    setIsDeletedQuoteRate(false);
    e.preventDefault();
    deleteQuoteRate({
      variables: {
        QuoteRateId: quoteRateId,
      },
    });
  };

  const goToPreviousStep = (e) => {
    setPrevious(true);
    e.preventDefault();
    updateQuote({
      variables: {
        QuoteInput: {
          QuoteId: data.Quote.QuoteId,
          HubSpotDealId: data.Quote.HubSpotDealId,
          WorkflowStep: "QUOTE_ITEMS",
        },
      },
    });
  };

  const proceedNextStep = (e) => {
    e.preventDefault();
    if (quoteRates && quoteRates.length === 0) {
      setStepError({
        warning: true,
        message: `No usage rates have been specified.`,
        secondaryMessage: `Are you sure you wish to continue without rates?`,
      });
      setProceed(false);
    } else {
      updateQuote({
        variables: {
          QuoteInput: {
            QuoteId: data.Quote.QuoteId,
            HubSpotDealId: data.Quote.HubSpotDealId,
            WorkflowStep: "CUSTOMISE_PROPOSAL",
          },
        },
      });
      setStepError({
        error: false,
        message: "",
        warning: false,
        secondaryMessage: "",
      });
      setProceed(true);
    }
  };

  const ignoreWarningStep = (e) => {
    updateQuote({
      variables: {
        QuoteInput: {
          QuoteId: data.Quote.QuoteId,
          HubSpotDealId: data.Quote.HubSpotDealId,
          WorkflowStep: "CUSTOMISE_PROPOSAL",
        },
      },
    });
    setStepError({
      error: false,
      message: "",
      warning: false,
      secondaryMessage: "",
    });
    setProceed(true);
  };

  const onDragEnd = (result) => {
    setIsUpdatedQuoteRates(false);
    const { draggableId, source, destination } = result;

    // dropped outside the tables
    if (!destination) {
      return;
    }

    const QuoteRateId = draggableId;
    const prevOrder = source.index;
    const newOrder = destination ? destination.index : prevOrder;
    const quoteRatesCopy = Array.from(quoteRates);

    let reorderItems = quoteRatesCopy;
    reorderItems.filter((rate) => rate.QuoteRateId === QuoteRateId)[0].Order =
      newOrder;
    reorderItems = reorderItems.map((rate) => {
      if (rate.QuoteRateId !== QuoteRateId) {
        // upward
        if (prevOrder > newOrder) {
          if (rate.Order >= newOrder) {
            rate.Order += 1;
          }
        }
        // downward
        else if (prevOrder < newOrder) {
          if (rate.Order > prevOrder && rate.Order <= newOrder) {
            rate.Order -= 1;
          }
        }
      }
      return rate;
    });
    setQuoteRates(reorderItems);
    updateQuoteRates({
      variables: {
        QuoteRatesList: reorderItems,
      },
    });
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "#eee" : "#fff",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const createStaticProposalHTML = (e) => {
    const quoteVersions = updatedQuoteData.updateQuote.QuoteVersions;
    const updatedQuote = updatedQuoteData.updateQuote;

    // if quote has existing quote versions data
    if (quoteVersions.length > 0) {
      // if quote modified timestamp is updated than latest quote version timestamp generate static html
      if (
        new Date(updatedQuote.ModifiedTimestamp) >
        new Date(quoteVersions[quoteVersions.length - 1].Timestamp)
      ) {
        generateStaticProposalHTML({
          variables: {
            QuoteId: updatedQuote.QuoteId,
            Token: updatedQuote.Token,
          },
        });
      }
      // skip generating of static html and go to send proposal page
      else {
        window.location.replace(`/send-proposal/${data.Quote.QuoteId}`);
      }
    }
    // if quote has no existing quote versions data generate static html
    else {
      generateStaticProposalHTML({
        variables: {
          QuoteId: updatedQuote.QuoteId,
          Token: updatedQuote.Token,
        },
      });
    }
  };

  return (
    <React.Fragment>
      {disableQuote && <div className="overlay"></div>}
      {stepError && stepError.error === true && (
        <SweetAlert
          error
          title={<span style={{ fontSize: "24px" }}>Error</span>}
          onConfirm={(e) => setStepError({ error: false, message: "" })}
          showCancel={true}
          onCancel={ignoreWarningStep}
          cancelBtnText={"Ignore and continue"}
          cancelBtnCssClass={"btn-secondary"}
          cancelBtnStyle={{ color: "#fff", textDecoration: "none" }}
        >
          {stepError.message}
        </SweetAlert>
      )}
      {stepError && stepError.warning && stepError.warning === true && (
        <SweetAlert
          warning
          title={<span style={{ fontSize: "24px" }}>No Usage Rates</span>}
          onConfirm={(e) =>
            setStepError({ warning: false, message: "", secondaryMessage: "" })
          }
          showCancel={true}
          onCancel={ignoreWarningStep}
          confirmBtnText={"Go back and add rates"}
          cancelBtnText={"Ignore and continue"}
          cancelBtnCssClass={"btn-secondary"}
          cancelBtnStyle={{ color: "#fff", textDecoration: "none" }}
        >
          {stepError.message}
          <br />
          {stepError.secondaryMessage}
        </SweetAlert>
      )}
      {stepError &&
        stepError.error === false &&
        proceed === true &&
        updatedQuoteData &&
        isInit === true && (
          <SweetAlert
            success
            title={<span style={{ fontSize: "24px" }}>Success</span>}
            onConfirm={(e) =>
              window.location.replace(
                `/customise-proposal/${data.Quote.QuoteId}`
              )
            }
            showConfirm={false}
            timeout={2000}
          >
            Quote rates step complete. Proceeding to next step.
          </SweetAlert>
        )}
      {
        // load live search and quote item builder UI when quote with the ID passed on the URL exists
        data && data.Quote && !loading && (
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col sm="6">
                    <h4 className="page-title">{`$ ${data.Quote.HubSpotDealValue} - ${data.Quote.Description}  - Quote Rates - JARVIS`}</h4>
                  </Col>
                </Row>
              </div>
              <h6>USAGE RATES</h6>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId={"quote-rates"}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              className="table-responsive"
                              key={"quote-rates"}
                            >
                              {data && data.Quote && (
                                <table className="table mb-0">
                                  <thead className="table-dark">
                                    <tr>
                                      {QUOTE_RATES_COLUMN_HEADERS.map(
                                        (column) => (
                                          <ColumnHeadersTooltip
                                            key={column.id}
                                            id={"product-pricing"}
                                            name={"usage-rates"}
                                            column={column}
                                            quoteType1={
                                              data.Quote.QuoteType1.Name
                                            }
                                            quoteType2={
                                              data.Quote.QuoteType2.Name
                                            }
                                          />
                                        )
                                      )}
                                      <th style={{ width: "40px" }}></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data.Quote && quoteRates ? (
                                      quoteRates.sort((a, b) =>
                                        a.Order > b.Order ? 1 : -1
                                      ) &&
                                      quoteRates.map((item, index) => (
                                        <Draggable
                                          key={item.QuoteRateId}
                                          draggableId={item.QuoteRateId}
                                          index={item.Order}
                                        >
                                          {(provided, snapshot) => (
                                            <tr
                                              key={item.QuoteRateId}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                            >
                                              <td style={{ padding: "5px" }}>
                                                <div
                                                  style={{
                                                    position: "relative",
                                                    top: "10px",
                                                  }}
                                                >
                                                  <i
                                                    className="fas fa-ellipsis-v"
                                                    style={{ color: "#bbb" }}
                                                  ></i>
                                                  <i
                                                    className="fas fa-ellipsis-v"
                                                    style={{ color: "#bbb" }}
                                                  ></i>
                                                </div>
                                                <div
                                                  style={{
                                                    width: "200px",
                                                    position: "relative",
                                                    top: "-10px",
                                                    left: "14px",
                                                  }}
                                                >
                                                  <Select
                                                    placeholder={
                                                      "Select document"
                                                    }
                                                    options={RATE_TYPES}
                                                    onChange={(e) =>
                                                      updateQuoteRateEntry(
                                                        e,
                                                        "RateType",
                                                        item.QuoteRateId
                                                      )
                                                    }
                                                    defaultValue={
                                                      item.RateType
                                                        ? {
                                                          value:
                                                            item.RateType.replace(
                                                              " ",
                                                              "_"
                                                            ).toUpperCase(),
                                                          label: titleCase(
                                                            item.RateType
                                                          ),
                                                        }
                                                        : null
                                                    }
                                                    menuPortalTarget={document.querySelector(
                                                      "body"
                                                    )}
                                                  />
                                                </div>
                                              </td>
                                              <td>
                                                <input
                                                  type="number"
                                                  defaultValue={item.Quote1Rate}
                                                  onChange={(e) =>
                                                    updateQuoteRateEntry(
                                                      e,
                                                      "Quote1Rate",
                                                      item.QuoteRateId
                                                    )
                                                  }
                                                  className="form-control"
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="number"
                                                  defaultValue={item.Quote2Rate}
                                                  onChange={(e) =>
                                                    updateQuoteRateEntry(
                                                      e,
                                                      "Quote2Rate",
                                                      item.QuoteRateId
                                                    )
                                                  }
                                                  className="form-control"
                                                />
                                              </td>
                                              <td>

                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  onChange={(e) => {
                                                    updateQuoteRateEntry(e, "RateUnits", item.QuoteRateId)
                                                    setRateUnitValue(e.currentTarget.value)
                                                  }}
                                                  defaultValue={
                                                    item.RateUnits
                                                      ?
                                                      item.RateUnits
                                                      : null
                                                  }
                                                />

                                                {/* <Select
                                                  placeholder={
                                                    "Select document"
                                                  }
                                                  options={RATE_UNITS}
                                                  onChange={(e) =>
                                                    updateQuoteRateEntry(
                                                      e,
                                                      "RateUnits",
                                                      item.QuoteRateId
                                                    )
                                                  }
                                                  defaultValue={
                                                    item.RateUnits
                                                      ? {
                                                        value:
                                                          item.RateUnits.replace(
                                                            " ",
                                                            "_"
                                                          ).toUpperCase(),
                                                        label: titleCase(
                                                          item.RateUnits
                                                        ),
                                                      }
                                                      : null
                                                  }
                                                  menuPortalTarget={document.querySelector(
                                                    "body"
                                                  )}
                                                /> */}
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  defaultValue={
                                                    item.CompareDesc
                                                  }
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    updateQuoteRateEntry(
                                                      e,
                                                      "CompareDesc",
                                                      item.QuoteRateId
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="number"
                                                  defaultValue={
                                                    item.ComparePrice
                                                  }
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    updateQuoteRateEntry(
                                                      e,
                                                      "ComparePrice",
                                                      item.QuoteRateId
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="number"
                                                  defaultValue={item.CompareQty}
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    updateQuoteRateEntry(
                                                      e,
                                                      "CompareQty",
                                                      item.QuoteRateId
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <button
                                                  style={{ width: "70px" }}
                                                  className="btn btn-danger"
                                                  onClick={(e) =>
                                                    deleteQuoteRateEntry(
                                                      e,
                                                      item.QuoteRateId
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </button>
                                              </td>
                                            </tr>
                                          )}
                                        </Draggable>
                                      ))
                                    ) : (
                                      <tr key={0}>
                                        <td
                                          colSpan={10}
                                          className="text-center"
                                        >
                                          No quote rates
                                        </td>
                                      </tr>
                                    )}
                                    {provided.placeholder}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>

                      <h6>ADD NEW RATES</h6>
                      <div className="table-responsive">
                        {data && data.Quote && (
                          <table className="table mb-0">
                            <thead className="table-light">
                              <tr>
                                {QUOTE_RATES_COLUMN_HEADERS.map((column) => (
                                  <ColumnHeadersTooltip
                                    key={column.id}
                                    id={"product-pricing"}
                                    name={"usage-rates-2"}
                                    column={column}
                                    quoteType1={data.Quote.QuoteType1.Name}
                                    quoteType2={data.Quote.QuoteType2.Name}
                                  />
                                ))}
                                <th style={{ width: "40px" }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr key={0}>
                                <td style={{ padding: "5px" }}>
                                  <div
                                    style={{
                                      position: "relative",
                                      top: "10px",
                                      visibility: "hidden",
                                    }}
                                  >
                                    <i
                                      className="fas fa-ellipsis-v"
                                      style={{ color: "#bbb" }}
                                    ></i>
                                    <i
                                      className="fas fa-ellipsis-v"
                                      style={{ color: "#bbb" }}
                                    ></i>
                                  </div>
                                  <div
                                    style={{
                                      width: "200px",
                                      position: "relative",
                                      top: "-10px",
                                      left: "14px",
                                    }}
                                  >
                                    <Creatable
                                      placeholder={"Select Usage Rates"}
                                      options={RATE_TYPES}
                                      onCreateOption={(e) => {
                                        SET_RATE_TYPES((state) => [
                                          ...state,
                                          createOption(e),
                                        ]);
                                        onNewQuoteRateChange(
                                          createOption(e),
                                          "RateType"
                                        );
                                        setRateTypeValue(createOption(e))
                                      }}
                                      defaultValue={null}
                                      value={rateTypeValue}
                                      menuPortalTarget={document.querySelector(
                                        "body"
                                      )}
                                      onChange={(e) => {
                                        onNewQuoteRateChange(e, "RateType")
                                        setRateTypeValue(e)
                                      }
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) =>
                                      onNewQuoteRateChange(e, "Quote1Rate")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) =>
                                      onNewQuoteRateChange(e, "Quote2Rate")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      onNewQuoteRateChange(e, "RateUnits")
                                      setRateUnitValue(e.currentTarget.value)
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                      onNewQuoteRateChange(e, "CompareDesc")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) =>
                                      onNewQuoteRateChange(e, "ComparePrice")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) =>
                                      onNewQuoteRateChange(e, "CompareQty")
                                    }
                                  />
                                </td>
                                <td>
                                  <button
                                    style={{ width: "70px" }}
                                    className="btn btn-success"
                                    onClick={(e) => addNewQuoteRate()}
                                  >
                                    Add
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <button
                        className="btn-icon btn btn-light btn-lg float-left"
                        onClick={goToPreviousStep}
                      >
                        {" "}
                        <span className="btn-icon-label">
                          <i className="ion ion-md-arrow-back mr-2"></i>
                        </span>{" "}
                        Previous Step
                      </button>
                      <button
                        className="btn-icon btn btn-primary btn-lg float-right"
                        onClick={proceedNextStep}
                      >
                        {" "}
                        <span className="btn-icon-label">
                          <i className="ion ion-md-arrow-forward mr-2"></i>
                        </span>{" "}
                        Next Step
                      </button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
      {
        // when data is loaded and quote id is not valid show quote does not exists error UI
        data && data.Quote === null && !loading && (
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col sm="6">
                    <h4 className="page-title">Error loading this page</h4>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      Quote with id {props.pathArg} does not exist.
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
    </React.Fragment>
  );
};

export default connect(null, { activateAuthLayout })(QuoteBuilderRates);
