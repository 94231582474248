import React, { useEffect, useState } from "react";
import {
  withRouter,
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import routes from "../routes";
import Layout from "./Layout";
import { LoginCallback, useOktaAuth } from "@okta/okta-react";
import Logout from "../containers/MainContent/Auth/Logout";
import { getOktaUser } from "../lib/oktaApi";
import { isAdmin } from "../lib/oktaConfig";
import { getCookie } from "../helpers/cookie";

function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <Layout>
          <WrappedComponent
            pathArg={this.props.match.params.pathArg}
          ></WrappedComponent>
        </Layout>
      );
    }
  };
}

const NotFound = () => {
  return <div>Not found</div>;
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  if (rest.forAdmin) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAdmin() ? <Component {...props} /> : <Redirect to="/logout" />
        }
      />
    );
  } else {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  // return (
  //   <Route
  //     {...rest}
  //     render={(props) =>
  //       isAdmin() ? <Component {...props} /> : <Redirect to="/logout" />
  //     }
  //   />
  // );
};

const BaseRoutes = () => {
  const [isHidden, setIsHidden] = useState(true);
  const { oktaAuth, authState } = useOktaAuth();

  const login = async () => {
    // await oktaAuth.signInWithRedirect({ originalUri: "/dashboard" });
    await oktaAuth.signInWithRedirect();
  };

  const logout = async () => {
    sessionStorage.setItem("user", null);
    await oktaAuth.signOut();
  };

  const checkOktaTokenLocalStorage = () => {
    const oktaToken = localStorage.getItem("okta-token-storage");
    if (oktaToken !== "{}") {
      // check if cookie user has no value or undefined
      const user = getCookie("user");
      if (user === "" || user === undefined) {
        oktaAuth.token.getUserInfo().then((res) => {
          document.cookie = `user=${JSON.stringify(res)}`;
        });
      }

      if (
        window.location.href.split("/").pop() == "login" ||
        window.location.href.split("/").pop() == ""
      ) {
        // get rol

        window.location = "/dashboard";
        console.log("redirecting to dashboard");
      } else {
        setIsHidden(false);
      }
    } else {
      console.log("okta token not found in local storage");
      if (window.location.href.split("/").pop() !== "login") {
        // window.location = "/login";
        console.log("redirecting to login");
        login();
      } else {
        // setIsHidden(false);
        logout();
      }
    }
  };

  useEffect(() => {
    getOktaUser();
    checkOktaTokenLocalStorage();
  }, []);

  return isHidden ? (
    <div></div>
  ) : (
    <>
      {routes.map((route, idx) =>
        route.ispublic ? (
          <Route
            exact
            path={route.path}
            component={withLayout(route.component)}
            key={idx}
          />
        ) : (
          <PrivateRoute
            exact
            path={route.path}
            component={withLayout(route.component)}
            key={idx}
            forAdmin={route.forAdmin}
            isAdmin={isAdmin}
          />
        )
      )}
      <Route path="*" component={NotFound} />
    </>
  );
};

export default BaseRoutes;
