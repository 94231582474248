import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

import { activateAuthLayout } from '../../../store/actions';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import SweetAlert from 'react-bootstrap-sweetalert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { getQuoteBuilderURL } from '../../../helpers/common';
import env from '../../../env';
/**
 * graphQL query for all the data needed for customise proposal UI
 * Quote - quote based from the passed quote id in the URL
 **/
const DATA = gql`
  query Data($QuoteId: ID) {
    Quote(QuoteId: $QuoteId) {
      QuoteId
      HubSpotDealId
      Description
      HubSpotDealValue
      HubSpotDealOwner
      PrimaryContact
      QuoteTypeId1
      QuoteType1 {
        QuoteTypeId
        Name
      }
      QuoteTypeId2
      QuoteType2 {
        QuoteTypeId
        Name
      }
      WorkflowStep
      AcceptedQuote
      ModifiedTimestamp
      Status
      SignatureNotRequired
      SignatureNotRequiredAuthorised
    }
    ProductTemplatesInserts {
      ProductId
      ProductTemplateId
      ProductTemplateName
      ProductTemplateTOCLine
    }
    QuoteItems(QuoteId: $QuoteId) {
      QuoteId
      LineId
      ProductId
      Description
      PriceType
      TemplateId
      ProductTemplates {
        ProductId
        ProductTemplateId
        ProductTemplateName
        ProductTemplateTOCLine
      }
      Product {
        Name
      }
    }
    ProposalInserts(QuoteId: $QuoteId) {
      QuoteId
      InsertId
      TemplateId
      LinkedLineId
      SortOrder
      AddedTimestamp
      ProductTemplates {
        ProductId
        ProductTemplateName
        ProductTemplateTOCLine
        Product {
          Name
        }
      }
    }
  }
`;

const UPDATE_QUOTE = gql`
  mutation updateQuote($QuoteInput: QuoteInput) {
    updateQuote(input: $QuoteInput) {
      QuoteId
      HubSpotDealId
      WorkflowStep
      AcceptedQuote
      ModifiedTimestamp
      Token
      SignatureNotRequired
      SignatureNotRequiredAuthorised
      QuoteVersions {
        VersionId
        File
        Timestamp
      }
    }
  }
`;

const GENERATE_STATIC_PROPOSAL = gql`
  mutation generateStaticProposalHTML($QuoteId: Int, $Token: String) {
    generateStaticProposalHTML(QuoteId: $QuoteId, Token: $Token) {
      message
      QuoteVersion {
        QuoteId
        VersionId
        Timestamp
        Token
        File
      }
    }
  }
`;

const CREATE_PROPOSAL_INSERTS = gql`
  mutation createProposalInserts($ProposalInserts: [ProposalInsertInput]) {
    createProposalInserts(input: $ProposalInserts) {
      InsertId
      QuoteId
      TemplateId
      SortOrder
      LinkedLineId
      AddedTimestamp
    }
  }
`;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, {
    ...removed,
    id: `p-custom-${Math.random().toString(36).substr(2, 20)}`,
  });

  const result = {};
  result[droppableSource.droppableId] = Array.from(source);
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle, productInserStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#fff',
  border: '1px solid #ccc',

  // styles we need to apply on draggables
  ...draggableStyle,
  ...productInserStyle,
});

const getLockedItemStyle = () => ({
  background: '#3ba2fb',
  color: '#fff',
  border: '1px solid #ccc',
  padding: '8px',
  margin: `0 0 ${grid}px 0`,
  maxWidth: '400px',
});

const getLockIconStyle = () => ({
  color: '#000',
  marginRight: '1rem',
});

const getProductInsertStyle = () => ({
  background: '#dadada',
  color: '#787878',
  border: '1px solid #ccc',
  padding: '8px',
  margin: `0 0 ${grid}px 0`,
  maxWidth: '400px',
});

const getListStyle = (isDraggingOver, isAvailableInserts = true) => ({
  background: isDraggingOver ? '#eee' : '#fff',
  border: isAvailableInserts ? '2px solid #ccc' : 'none',
  padding: isAvailableInserts ? '0.75rem 1rem' : 0,
  paddingBottom: 0,
  minHeight: isAvailableInserts ? '400px' : 'unset',
  maxWidth: '400px',
  width: '100%',
});

const QuoteBuilderCustomiseProposal = (props) => {
  props.activateAuthLayout();

  const user = useSelector((state) => state.User.user);

  // initialize states
  const [isInit, setIsInit] = useState(false);
  const [stepError, setStepError] = useState(null);
  const [proceed, setProceed] = useState(false);
  const [previous, setPrevious] = useState(false);
  const [disableQuote, setDisableQuote] = useState(false);
  const [signatureNotRequired, setSignatureNotRequired] = useState(false);

  // initialize apollo graphql queries and mutations
  const { data, loading } = useQuery(DATA, {
    variables: { QuoteId: props.pathArg },
  });
  const [updateQuote, { data: updatedQuoteData }] = useMutation(UPDATE_QUOTE);
  const [
    generateStaticProposalHTML,
    { data: generatedStaticProposalHTMLData },
  ] = useMutation(GENERATE_STATIC_PROPOSAL);
  const [createProposalInserts, { data: proposalInsertsData }] = useMutation(
    CREATE_PROPOSAL_INSERTS
  );

  /**
   * useEffect() - function equivalent to componentDidMount
   **/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // populate quoteRates state on load of page
    if (data && data.Quote && isInit === false) {
      setState({
        ...state,
        defaultTemplatesStart: returnDefaultTemplates('start'),
        defaultTemplatesEnd: [
          ...returnDefaultTemplates('end'),
          {
            ProductId: 'system',
            ProductTemplateId: null,
            ProductTemplateTOCLine: 'Signature',
          },
        ],
        items: listProposalInserts(),
        selected: data.ProductTemplatesInserts.filter(
          (template) => template.ProductId === 'custom'
        ).map((item, index) => {
          return {
            ...item,
            id: `p-custom-${index}`,
            ProductTemplateName: item.ProductTemplateName,
          };
        }),
      });
      // disable screen when status is accepted, declined or voided
      const status = data.Quote.Status;
      setDisableQuote(
        status === 'accepted' || status === 'declined' ? true : false
      );

      // redirect to the current workflow step
      if (
        data.Quote &&
        data.Quote.AcceptedQuote &&
        data.Quote.WorkflowStep == 'CUSTOMER_ACCEPTED'
      ) {
        data.Quote &&
          data.Quote.WorkflowStep !== 'CUSTOMISE_PROPOSAL' &&
          window.location.replace(
            getQuoteBuilderURL(
              data.Quote.WorkflowStep,
              data.Quote.QuoteId,
              data.Quote.HubSpotDealId
            )
          );
      }

      // if (data.Quote && data.Quote.AcceptedQuote) {
      //   if (
      //     [
      //       'QUOTE_SETUP',
      //       'QUOTE_ITEMS',
      //       'QUOTE_RATES',
      //       'CUSTOMISE_PROPOSAL',
      //       'SEND_PROPOSAL',
      //     ].includes(data.Quote.WorkflowStep)
      //   ) {
      //     const quote = {};
      //     quote['QuoteId'] = data.Quote.QuoteId;
      //     quote['WorkflowStep'] = 'CUSTOMISE_CAF';
      //     updateQuote({
      //       variables: {
      //         QuoteInput: quote,
      //       },
      //     });
      //     window.location.replace(
      //       getQuoteBuilderURL(
      //         'CUSTOMISE_CAF',
      //         data.Quote.QuoteId,
      //         data.Quote.HubSpotDealId
      //       )
      //     );
      //     return;
      //   } else {
      //     window.location.replace(
      //       getQuoteBuilderURL(
      //         data.Quote.WorkflowStep,
      //         data.Quote.QuoteId,
      //         data.Quote.HubSpotDealId
      //       )
      //     );
      //     return;
      //   }
      // }
      setIsInit(true);
    }

    if (previous === true && updatedQuoteData) {
      window.location.replace(`/quote-rates/${data.Quote.QuoteId}`);
      setPrevious(false);
    }

    if (proceed === true && generatedStaticProposalHTMLData) {
      window.location.replace(`/send-proposal/${data.Quote.QuoteId}`);
    }
  });

  const listProposalInserts = () => {
    let proposalInserts = null;
    if (data.ProposalInserts.length > 0) {
      data.ProposalInserts.sort(function (a, b) {
        return a.SortOrder < b.SortOrder ? -1 : 1;
      });
    }
    if (data.ProposalInserts.length > 0) {
      proposalInserts = data.ProposalInserts.map((item, index) => {
        let productTemplates = item && item.ProductTemplates;
        if (productTemplates) {
          let productTemplateName =
            (item &&
              item.ProductTemplates &&
              item.ProductTemplates.ProductTemplateName) ||
            '';
          if (
            item &&
            item.ProductTemplates &&
            item.ProductTemplates.ProductId &&
            item.ProductTemplates.ProductId !== 'custom'
          ) {
            let productName =
              (item &&
                item.ProductTemplates &&
                item.ProductTemplates.Product &&
                item.ProductTemplates.Product.Name) ||
              '';
            productTemplateName = `${productName} - ${productTemplateName}`;
          }
          return {
            ...item,
            ProductTemplateName: productTemplateName,
            id: `i-insert-${index}`,
            LineId: item.LinkedLineId ? item.LinkedLineId : null,
            ProductTemplateId: item.TemplateId,
            isProductIsert: item.LinkedLineId ? true : false,
          };
        }
      }).filter((item) => {
        return item !== undefined;
      });

      let included_templates = [];

      let quoteItemInserts = data.QuoteItems.filter(
        (item) => item.TemplateId !== null
      )
        .map((items, index) => {
          if (included_templates.includes(items.TemplateId)) {
            return;
          }

          included_templates.push(items.TemplateId);

          let productTemplates = items && items.ProductTemplates;
          if (productTemplates) {
            let productTemplateName =
              (productTemplates && productTemplates.ProductTemplateName) || '';
            if (items && items.ProductId && items.ProductId !== 'custom') {
              let productName =
                (items && items.Product && items.Product.Name) || '';
              productTemplateName = `${productName} - ${productTemplateName}`;
            }
            return {
              ...productTemplates,
              ProductTemplateName: productTemplateName,
              id: `p-insert-${index}`,
              LineId: items.LineId,
              ProductTemplateId: items.TemplateId,
              isProductIsert: true,
            };
          }
        })
        .filter((item) => {
          return item !== undefined;
        });

      let newQuoteTemplates = [...proposalInserts, ...quoteItemInserts];
      newQuoteTemplates = newQuoteTemplates.filter((v, i, a) => {
        if (v.LineId !== null) {
          let index = a.findIndex((t) => t.LineId == v.LineId);
          return index == i;
        }
        return v;
      });
      let productInserts = newQuoteTemplates.filter(
        (item) => item.isProductIsert === true
      );
      if (productInserts.length > quoteItemInserts.length) {
        newQuoteTemplates = newQuoteTemplates
          .map((item) => {
            return item;
          })
          .filter((iitem) => {
            if (iitem.isProductIsert === false) {
              return iitem;
            } else if (iitem.isProductIsert === true) {
              let newLineQuoteExist = quoteItemInserts.filter(
                (fItem) => fItem.LineId == iitem.LineId
              );
              if (newLineQuoteExist.length > 0) {
                return iitem;
              } else {
                return null;
              }
            }
          });
      }
      proposalInserts = newQuoteTemplates;
    } else {
      proposalInserts = data.QuoteItems.filter(
        (item) => item.TemplateId !== null
      )
        .map((items, index) => {
          let productTemplates = items && items.ProductTemplates;
          if (productTemplates) {
            let productTemplateName =
              (productTemplates && productTemplates.ProductTemplateName) || '';
            if (items && items.ProductId && items.ProductId !== 'custom') {
              let productName =
                (items && items.Product && items.Product.Name) || '';
              productTemplateName = `${productName} - ${productTemplateName}`;
            }
            return {
              ...productTemplates,
              ProductTemplateName: productTemplateName,
              id: `p-insert-${index}`,
              LineId: items.LineId,
              ProductTemplateId: items.TemplateId,
              isProductIsert: true,
            };
          }
        })
        .filter((item) => {
          return item !== undefined;
        });
    }
    return proposalInserts;
  };

  const returnDefaultTemplates = (type = 'start') => {
    const defaultTemplates = data.ProductTemplatesInserts.filter((template) => {
      let isTemplateStart = false;
      env[`DEFAULT_TEMPLATES_${type.toUpperCase()}`]
        .split(',')
        .map(
          (templateStart) =>
            (isTemplateStart =
              parseInt(templateStart) === parseInt(template.ProductTemplateId)
                ? true
                : false)
        );
      return isTemplateStart;
    });
    return defaultTemplates;
  };

  const goToPreviousStep = (e) => {
    setPrevious(true);
    e.preventDefault();
    updateQuote({
      variables: {
        QuoteInput: {
          QuoteId: data.Quote.QuoteId,
          HubSpotDealId: data.Quote.HubSpotDealId,
          WorkflowStep: 'QUOTE_RATES',
        },
      },
    });
  };

  const proceedNextStep = (e) => {
    e.preventDefault();
    saveProposalInserts();
  };

  const createStaticProposalHTML = () => {
    const quoteVersions = updatedQuoteData.updateQuote.QuoteVersions;
    const updatedQuote = updatedQuoteData.updateQuote;

    // if quote has existing quote versions data
    if (quoteVersions.length > 0) {
      // if quote modified timestamp is updated than latest quote version timestamp generate static html
      if (
        new Date(updatedQuote.ModifiedTimestamp) >
        new Date(quoteVersions[quoteVersions.length - 1].Timestamp)
      ) {
        generateStaticProposalHTML({
          variables: {
            QuoteId: parseInt(updatedQuote.QuoteId, 10),
            Token: updatedQuote.Token,
          },
        });
      }
      // skip generating of static html and go to send proposal page
      else {
        window.location.replace(`/send-proposal/${data.Quote.QuoteId}`);
      }
    }
    // if quote has no existing quote versions data generate static html
    else {
      generateStaticProposalHTML({
        variables: {
          QuoteId: parseInt(updatedQuote.QuoteId, 10),
          Token: updatedQuote.Token,
        },
      });
    }
  };

  const [state, setState] = useState({
    items: [],
    selected: [],
    defaultTemplatesStart: [],
    defaultTemplatesEnd: [],
  });

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  const id2List = {
    droppable: 'items',
    droppable2: 'selected',
  };

  const getList = (id) => state[id2List[id]];

  const onDragEnd = (result) => {
    const { source, destination } = result;
    const draggedItem = getList(source.droppableId)[source.index];
    const isDraggedItemExist =
      state.items.filter(
        (item) =>
          parseInt(item.ProductTemplateId) ===
          parseInt(draggedItem.ProductTemplateId)
      ).length > 0
        ? true
        : false;

    // dropped outside the list
    if (!destination) {
      return;
    }

    // if the template item already exists - don't add the item
    if (isDraggedItemExist && source.droppableId !== destination.droppableId) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      setState({
        ...state,
        items: items,
        selected: state.selected,
      });
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      setState({
        ...state,
        items: result.droppable,
        selected: result.droppable2,
      });
    }
  };

  const deleteInsert = (item) => {
    let inserts = [...state.items];
    const index = state.items.map((insert) => insert.id).indexOf(item.id);
    inserts.splice(index, 1);
    setState({
      ...state,
      items: inserts,
    });
  };

  const saveProposalInserts = () => {
    const inserts = state.items.map((item, index) => {
      return {
        QuoteId: parseInt(data.Quote.QuoteId, 10),
        SortOrder: index,
        TemplateId: parseInt(item.ProductTemplateId),
        LinkedLineId: item.LineId ? parseInt(item.LineId, 10) : null,
      };
    });
    createProposalInserts({
      variables: {
        ProposalInserts: inserts,
      },
    });

    const prevInserts = data.ProposalInserts.map((insert) => insert.TemplateId);
    const newInserts = inserts.map((insert) => insert.TemplateId);
    const isUpdated =
      JSON.stringify(prevInserts) === JSON.stringify(newInserts) ? false : true;
    saveQuote(isUpdated);
  };

  const saveQuote = (isUpdated) => {
    updateQuote({
      variables: {
        QuoteInput: {
          QuoteId: data.Quote.QuoteId,
          HubSpotDealId: data.Quote.HubSpotDealId,
          WorkflowStep: 'SEND_PROPOSAL',
          SignatureNotRequired: signatureNotRequired ? 0 : null,
          SignatureNotRequiredAuthorised: signatureNotRequired
            ? JSON.stringify(user)
            : null,
          forceUpdate: isUpdated,
        },
      },
    });
    console.log('data',data)
    setStepError({ error: false, message: '' });
    setProceed(true);
  };

  return (
    <React.Fragment>
      {disableQuote && <div className="overlay"></div>}
      {stepError && stepError.error === true && (
        <SweetAlert
          error
          title={<span style={{ fontSize: '24px' }}>Error</span>}
          onConfirm={(e) => setStepError({ error: false, message: '' })}
        >
          {stepError.message}
        </SweetAlert>
      )}
      {stepError &&
        stepError.error === false &&
        proceed === true &&
        proposalInsertsData &&
        updatedQuoteData &&
        isInit === true && (
          <SweetAlert
            success
            title={<span style={{ fontSize: '24px' }}>Success</span>}
            onConfirm={() => createStaticProposalHTML()}
            showConfirm={false}
            timeout={2000}
          >
            Customise Proposal step complete. Proceeding to next step.
          </SweetAlert>
        )}
      {
        // load live search and quote item builder UI when quote with the ID passed on the URL exists
        data && data.Quote && !loading && (
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col sm="6">
                    <h4 className="page-title">{`$ ${data.Quote.HubSpotDealValue} - ${data.Quote.Description}  - Customise Proposal - JARVIS`}</h4>
                  </Col>
                </Row>
              </div>
              <h6>CUSTOMISE PROPOSAL</h6>

              <Card>
                <CardBody>
                  <Row>
                    <Col lg={{ size: 8, offset: 2 }}>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Row>
                          <Col>
                            <h4>Proposal Inserts</h4>
                            {state &&
                              state.defaultTemplatesStart &&
                              state.defaultTemplatesStart.map(
                                (template, index) => (
                                  <div key={index} style={getLockedItemStyle()}>
                                    <i
                                      className="fas fa-lock"
                                      style={getLockIconStyle()}
                                    ></i>
                                    {template.ProductTemplateTOCLine}
                                  </div>
                                )
                              )}
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  style={getListStyle(
                                    snapshot.isDraggingOver,
                                    false
                                  )}
                                >
                                  {state &&
                                    state.items &&
                                    state.items.map((item, index) => (
                                      <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style,
                                              item.isProductIsert
                                                ? getProductInsertStyle()
                                                : null
                                            )}
                                          >
                                            {item.isProductIsert && (
                                              <i
                                                className="fas fa-link mr-3"
                                                style={{ color: '#000' }}
                                              ></i>
                                            )}
                                            {item.ProductTemplateName}
                                            <span className="float-right">
                                              {!item.isProductIsert &&
                                                item.ProductTemplateName &&
                                                item.ProductTemplateName.toLowerCase() !==
                                                  'your quotes' && (
                                                  <small
                                                    className="mr-2"
                                                    style={{
                                                      cursor: 'pointer',
                                                    }}
                                                    onClick={() =>
                                                      deleteInsert(item)
                                                    }
                                                  >
                                                    Delete
                                                  </small>
                                                )}
                                              <i className="fas fa-bars"></i>
                                            </span>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                            {state &&
                              state.defaultTemplatesEnd &&
                              state.defaultTemplatesEnd.map(
                                (template, index) => (
                                  <div key={index} style={getLockedItemStyle()}>
                                    <i
                                      className="fas fa-lock"
                                      style={getLockIconStyle()}
                                    ></i>
                                    {template.ProductTemplateTOCLine}
                                  </div>
                                )
                              )}

                            {/* This is able for future used. */}
                            {/* <input
                              className="m-2"
                              type="checkbox"
                              defaultChecked={data.Quote.SignatureNotRequired}
                              onChange={(e) =>
                                setSignatureNotRequired(e.target.checked)
                              }
                            />
                            Customer acceptance not required for this quote */}
                          </Col>
                          <Col>
                            <h4>Available Inserts</h4>
                            <Droppable
                              isDropDisabled={true}
                              droppableId="droppable2"
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  style={getListStyle(snapshot.isDraggingOver)}
                                >
                                  {state &&
                                    state.selected &&
                                    state.selected.map((item, index) => (
                                      <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <>
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                            >
                                              {item.ProductTemplateName}
                                              <i className="fas fa-bars float-right"></i>
                                            </div>
                                            {snapshot.isDragging && (
                                              <div style={getItemStyle()}>
                                                {item.ProductTemplateName}
                                                <span className="float-right">
                                                  <i className="fas fa-bars"></i>
                                                </span>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </Draggable>
                                    ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </Col>
                        </Row>
                      </DragDropContext>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <button
                        className="btn-icon btn btn-light btn-lg float-left"
                        onClick={goToPreviousStep}
                      >
                        {' '}
                        <span className="btn-icon-label">
                          <i className="ion ion-md-arrow-back mr-2"></i>
                        </span>{' '}
                        Previous Step
                      </button>
                      <button
                        className="btn-icon btn btn-primary btn-lg float-right"
                        onClick={proceedNextStep}
                      >
                        {' '}
                        <span className="btn-icon-label">
                          <i className="ion ion-md-arrow-forward mr-2"></i>
                        </span>{' '}
                        Next Step
                      </button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
      {
        // when data is loaded and quote id is not valid show quote does not exists error UI
        data && data.Quote === null && !loading && (
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col sm="6">
                    <h4 className="page-title">Error loading this page</h4>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      Quote with id {props.pathArg} does not exist.
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
    </React.Fragment>
  );
};

export default connect(null, { activateAuthLayout })(
  QuoteBuilderCustomiseProposal
);
